/* eslint-disable */
import React from 'react';

import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import Popup from 'components/organisms/Popup';

interface PolicyPopupProps {
  isOpen: boolean;
  handleClose?: () => void;
  handleOK?: () => void;
}

const PolicyPopup: React.FC<PolicyPopupProps> = ({
  isOpen, handleClose, handleOK
}) => (
  <Popup
    hasCloseButton
    isOpenModal={isOpen}
    title="ĐIỀU KHOẢN LƯU TRỮ THÔNG TIN"
    modifiers="policy"
    // sub="Chi tiết điều khoản"
    handleClose={handleClose}
  >
    <div className="o-popup_wrapContent">
      <div className="o-popup_content">
        <Text modifiers={['18x28', 'pattrickBlue']}>
          Chi tiết điều khoản
          <ul style={{ paddingLeft: '20px' }}>
            <li>
              Tôi đồng ý cho Công ty TNHH Dinh Dưỡng 3A Việt Nam lưu trữ và sử dụng các thông tin tôi đã cung cấp ở trên.
            </li>
            <li>
              Tôi đồng ý cho Công ty TNHH Dinh Dưỡng 3A Việt Nam liên hệ tôi qua thư điện tử/ điện thoại/ tin
              nhắn về các nội dung: thông tin về hội nghị các điểm tin chính sau hội nghị của công ty.
            </li>
            <li>
              Tôi hiểu rằng thông tin đăng ký của tôi sẽ được chia sẻ cho các đơn vị ủy quyền của Công ty TNHH
              Dinh Dưỡng 3A Việt Nam nhằm mục đích trên.
            </li>
            <li>
              Tôi hiểu rằng để ngừng nhận thông tin thì tôi phải liên hệ với nhân viên Công ty TNHH Dinh Dưỡng
              3A Việt Nam thông qua số Tổng đài: 0918 454 049.
            </li>
            <li>
              Tôi hiểu rằng để đảm bảo an toàn tài khoản đăng nhập vào chương trình, tôi tuyệt đối KHÔNG CUNG CẤP THÔNG TIN đăng nhập, mật khẩu, mã OTP,... cho bất cứ ai. Nếu xảy ra sự cố tranh
              chấp về tài khoản đăng nhập tôi hoàn toàn chịu mọi trách nhiệm.
            </li>
            <li>
              Tôi hiểu rằng Công ty TNHH Dinh Dưỡng 3A Việt Nam có quyền dùng thông tin trúng thưởng của
              tôi để báo cáo các cơ quan nhà nước hoặc để quảng cáo mà không phải trả thêm khoản chi phí nào.
            </li>
          </ul>
        </Text>
      </div>
    </div>
    {/* <div className="o-popup_button">
      <Button
        size="h44"
        onClick={handleOK}
      >
        TÔI ĐỒNG Ý VỚI ĐIỀU KHOẢN NÀY
      </Button>
    </div> */}
  </Popup>
);

export default PolicyPopup;
