import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import LayerLogin from './layout';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Container from 'components/organisms/Container';
import Form from 'components/organisms/Form';
import BannerLogin from 'components/templates/BannerLogin';
import { KeyRoute, returnPath } from 'helpers/redirectToPage';
import { useAsync } from 'hooks/useAsync';
import useWindowDimensions from 'hooks/useWindowDimension';
import { LoginParamsTypes } from 'services/auth/type';
import { getUserLoginAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMemberProfileAction } from 'store/profile';
import { getSystemAction, goLiveByLogin } from 'store/system';

const initialize = {
  // loginPopup: false,
  registerPopup: false,
  forgotPasswordPopup: false,
  successResetPasswordPopup: false,
};

const schema = yup.object().shape({
  user_name: yup.string().required('Tên truy cập là bắt buộc !'),
  password: yup.string().required('Vui lòng nhập mật khẩu !'),
});

interface LoginProps extends MienTypes { }

const Login: React.FC<LoginProps> = ({ mien }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const systemData = useAppSelector((state) => state.system.data);

  const method = useForm<LoginParamsTypes>({
    resolver: yupResolver(schema),
  });
  const { height } = useWindowDimensions();

  const [openPopup, setOpenPopup] = useState<PopupLoginProps>(initialize);

  const handlePopup = (name?: string) => {
    setOpenPopup(name ? { ...initialize, [name]: true } : initialize);
  };

  // LOGIN ASYNC
  const [loginExcute, loginState] = useAsync(
    (params: LoginParamsTypes) => dispatch(getUserLoginAction(params)).then(unwrapResult),
    {
      onSuccess: () => {
        method.reset();
        dispatch(goLiveByLogin(true));
        dispatch(getMemberProfileAction());

        handlePopup();
        if (systemData?.redirectToPage) {
          navigate(returnPath(systemData.redirectToPage as KeyRoute));
          dispatch(getSystemAction());
        }
      },
      onFailed: (err) => {
        const errors = err as ErrorResponse[];
        if (Array.isArray(errors)) {
          errors.forEach((val) => {
            method.setError(val.field as any, { message: val.message });
          });
        }
      },
    },
  );

  const submitLogin = (dataLogin: LoginParamsTypes) => {
    loginExcute({
      user_name: dataLogin.user_name,
      password: dataLogin.password,
    });
  };

  return (
    <LayerLogin openPopup={openPopup} handlePopup={handlePopup} mien={mien}>
      <div className="t-loginEvent" style={{ height: `${height}px` }}>
        <Container>
          <div className="t-loginEvent_wrap">
            <div className="t-loginEvent_left">
              <BannerLogin mien={mien} />
            </div>
            <div className="t-loginEvent_right">
              <div className="t-loginEvent_form">
                <div className="t-loginEvent_form_wrapcontent">
                  <div className="t-loginEvent_form_headingWrapper">
                    <div className="t-loginEvent_form_heading">
                      <div className="t-loginEvent_form_title">
                        <Text modifiers={['linearMainText2025', '700', '24x36']}>
                          ĐĂNG NHẬP
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="t-loginEvent_form-note">
                    <Text modifiers={['dimGray', '400', 'center', '16x22']}>
                      Tên Truy cập và Mật khẩu đã được gửi qua tin nhắn xác nhận
                      <br />
                      đăng ký của Quý khách hàng
                    </Text>
                  </div>
                  <Form method={method} submitForm={submitLogin}>
                    <div className="t-loginEvent_form_body">
                      <div className="t-loginEvent_input">
                        <Controller
                          control={method.control}
                          name="user_name"
                          defaultValue=""
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Input
                              isRequired
                              modifiers={['border', 'login']}
                              label="Tên truy cập"
                              id="user_name"
                              value={value}
                              placeholder="Nhập tên truy cập"
                              type="text"
                              error={error?.message}
                              handleChange={onChange}
                              alignLabel="left"
                            />
                          )}
                        />
                      </div>
                      <div className="t-loginEvent_input">
                        <Controller
                          control={method.control}
                          name="password"
                          defaultValue=""
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Input
                              isRequired
                              label="Mật khẩu"
                              id="password"
                              value={value}
                              modifiers={['border', 'login']}
                              placeholder="Nhập mật khẩu"
                              type="password"
                              error={error?.message}
                              handleChange={onChange}
                              alignLabel="left"
                            />
                          )}
                        />
                      </div>
                      <div className="t-loginEvent_submit button-2025">
                        <Button modifiers="primary-2025" loading={loginState.loading} type="submit">
                          Đăng nhập
                        </Button>
                      </div>
                      <div
                        className="t-loginEvent_forgotPassword"
                        onClick={() => handlePopup('forgotPasswordPopup')}
                      >
                        <Text modifiers={['19x30', '700', 'center', 'uppercase', 'celestialBlue']}>
                          Quên Thông Tin Đăng Nhập?
                        </Text>
                      </div>
                      <div className="t-loginEvent_divider" />
                      <div className="t-loginEvent_register button-2025">
                        <Button modifiers="primary-2025" type="submit" onClick={() => handlePopup('registerPopup')}>
                          Đăng ký
                        </Button>
                      </div>
                      {/* <div className="t-loginEvent_wrapRegister">
                        <Text type="span" modifiers={['24x32', 'pattrickBlue']}>
                          Chưa có tài khoản?
                        </Text>
                        <div
                          className="t-loginEvent_register"
                          onClick={() => handlePopup('registerPopup')}
                        >
                          <Text type="span" modifiers={['700', '24x32']}>
                            Đăng ký ngay
                          </Text>
                        </div>
                      </div> */}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="t-loginEvent_note">
          {mien === 'south' ? (
            <Text modifiers={['dimGray', 'right', '18x24', '400', 'brandon']}>
              Chương trình phối hợp tổ chức bởi Trung Tâm Đào Tạo Nhân Lực Y Tế
              Theo Nhu Cầu Xã Hội,
              <br />
              Đại Học Y Dược Thành Phố Hồ Chí Minh, Abbott và Công ty TNHH Dinh
              dưỡng 3A (Việt Nam)
            </Text>
          ) : (
            <Text modifiers={['dimGray', 'right', '18x24', '400', 'brandon']}>
              Chương trình phối hợp tổ chức Bệnh viện Đại Học Y Hà Nội,
              <br />
              Trường Đại Học Y Hà Nội, Abbott và Công ty TNHH Dinh dưỡng 3A
              (Việt Nam)
            </Text>
          )}
        </div>
      </div>
    </LayerLogin>
  );
};

export default Login;
