// import { yupResolver } from '@hookform/resolvers/yup';
// import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
// import { useForm } from 'react-hook-form';
// import { useNavigate } from 'react-router-dom';
// import * as yup from 'yup';

import Register from '../Register';
import SuccessForgotPasswordPopup from '../SuccessForgotPasswordPopup';

import Popup from 'components/organisms/Popup';
import ForgotPasswordFormTemplate from 'components/templates/ForgotPasswordForm';
// import { KeyRoute, returnPath } from 'helpers/redirectToPage';
// import { useAsync } from 'hooks/useAsync';
// import { LoginParamsTypes } from 'services/auth/type';
// import { getUserLoginAction } from 'store/auth';
// import { useAppDispatch, useAppSelector } from 'store/hooks';
// import { getMemberProfileAction } from 'store/profile';
// import { getSystemAction, goLiveByLogin } from 'store/system';

type LayerLoginProps = {
  openPopup: PopupLoginProps;
  disabledLogin?: boolean;
  handlePopup: (name?: NamePopupTypes) => void;
  children?: React.ReactNode;
} & MienTypes;

const LayoutLogin: React.FC<LayerLoginProps> = ({
  children, disabledLogin, openPopup, handlePopup,
  mien,
}) => {
  // const navigator = useNavigate();

  // const [openPopup, setOpenPopup] = useState({
  //   policy: false,
  //   otp: false,
  //   success: false,
  // });

  // const dispatch = useAppDispatch();
  // const systemData = useAppSelector((state) => state.system.data);
  // const schema = yup.object().shape({
  //   user_name: yup.string().required('Số điện thoại là bắt buộc !'),
  //   password: yup.string().required('Vui lòng nhập mật khẩu !'),
  // });
  // const method = useForm<LoginParamsTypes>({
  //   resolver: yupResolver(schema),
  // });

  const [numberPhoneReset, setNumberPhoneReset] = useState('');
  // LOGIN ASYNC
  // const [loginExcute, loginState] = useAsync(
  //   (params: LoginParamsTypes) => dispatch(getUserLoginAction(params)).then(unwrapResult),
  //   {
  //     onSuccess: () => {
  //       method.reset();
  //       dispatch(goLiveByLogin(true));
  //       dispatch(getMemberProfileAction());

  //       handlePopup();
  //       if (systemData?.redirectToPage) {
  //         navigator(returnPath(systemData.redirectToPage as KeyRoute));
  //         dispatch(getSystemAction());
  //       }
  //     },
  //     onFailed: (err) => {
  //       const errors = err as ErrorResponse[];
  //       if (Array.isArray(errors)) {
  //         errors.forEach((val) => {
  //           method.setError(val.field as any, { message: val.message });
  //         });
  //       }
  //     },
  //   },
  // );

  // const submitLogin = (dataLogin: LoginParamsTypes) => {
  //   loginExcute({
  //     user_name: dataLogin.user_name,
  //     password: dataLogin.password,
  //   });
  // };

  return (
    <>
      {children}
      {/* <Popup
        title="Đăng nhập"
        isOpenModal={openPopup.loginPopup}
        modifiers="formLogin"
        hasCloseButton
        handleClose={() => handlePopup()}
      >
        <Form method={method} submitForm={submitLogin}>
          <div className="t-loginEvent_form">
            <div className="t-loginEvent_input">
              <Controller
                control={method.control}
                name="user_name"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    isRequired
                    modifiers={['border', 'h52']}
                    label="Số điện thoại"
                    id="user_name"
                    value={value}
                    placeholder="Nhập số điện thoại"
                    type="text"
                    error={error?.message}
                    handleChange={onChange}
                  />
                )}
              />
            </div>
            <div className="t-loginEvent_input">
              <Controller
                control={method.control}
                name="password"
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    isRequired
                    label="Mật khẩu"
                    id="password"
                    value={value}
                    modifiers={['border', 'h52']}
                    placeholder="Nhập mật khẩu"
                    type="password"
                    error={error?.message}
                    handleChange={onChange}
                  />
                )}
              />
            </div>
            <div className="t-loginEvent_submit">
              <Button size="h44" loading={loginState.loading} type="submit">
                Đăng nhập
              </Button>
            </div>
            <div
              className="t-loginEvent_forgotPassword"
              onClick={() => handlePopup()}
            >
              <Text modifiers={['24x32', '700', 'center']}>
                Quên mật khẩu
              </Text>
            </div>
            <div className="t-loginEvent_wrapRegister">
              <Text type="span" modifiers={['24x32', 'pattrickBlue']}>
                Chưa có tài khoản?
              </Text>
              <div
                className="t-loginEvent_register"
                onClick={() => handlePopup('registerPopup')}
              >
                <Text type="span" modifiers={['700', '24x32']}>
                  Đăng ký ngay
                </Text>
              </div>
            </div>
          </div>
        </Form>
      </Popup> */}

      {/* FORGOT PASSWORD POPUP  */}
      <Popup
        isOpenModal={openPopup.forgotPasswordPopup}
        modifiers="forgotPassword"
        title="Quên Thông Tin Đăng Nhập"
        hasCloseButton
        handleClose={() => handlePopup()}
      >
        <ForgotPasswordFormTemplate
          handleTogglePopup={(name) => handlePopup(name)}
          handleSubmitForm={(phone) => {
            setNumberPhoneReset(phone);
            handlePopup('successResetPasswordPopup');
          }}
        />
      </Popup>

      {/* SUCCESS RESET PASSWORD POPUP  */}
      <SuccessForgotPasswordPopup
        phone={numberPhoneReset}
        openPopup={openPopup.successResetPasswordPopup}
        handleClose={() => handlePopup()}
      />
      {/* <Popup
        isOpenModal={openPopup.successResetPasswordPopup}
        modifiers="resetPassword"
        hasCloseButton
        title="Khôi phục mật khẩu Thành công"
        handleClose={() => handlePopup()}
      >
        <div className="t-resetPassword_wrapper">
          <Text modifiers={['pattrickBlue', '24x36', 'center']}>
            Mật khẩu đã được gửi về số điện thoại
            {' '}
            {`(+84) ${cleanRegionPhoneNumber(numberPhoneReset)}`}
            {' '}
            thành công.
          </Text>
          <div className="t-resetPassword_button">
            <Button
              onClick={() => handlePopup('loginPopup')}
            >
              Xác nhận
            </Button>
          </div>
        </div>
      </Popup> */}

      {/* REGISTER POPUP  */}

      <Popup
        modifiers="register"
        isOpenModal={openPopup.registerPopup}
        title="Đăng ký tham gia"
        hasCloseButton
        handleClose={() => handlePopup()}
      >
        <Register
          modifier="popup"
          mien={mien}
          disabledLogin={disabledLogin}
          handleLogin={() => handlePopup()}
        />
      </Popup>
    </>
  );
};

export default LayoutLogin;
