import {
  CheckRegisterType, InvitationCodeType, InvitationResponseType, RegisterMemberType,
  VerifyOTPParamsTypes
} from './type';

import axiosInstance from 'services/common/instance';

const getBanner = async () => {
  const res = await axiosInstance.post('/example');
  return res.data;
};
export default getBanner;

export const registerMemberService = async (params: RegisterMemberType): Promise<any> => {
  const res = await axiosInstance.post('/members/register', params);
  return res.data;
};

export const checkRegisterService = async (params: CheckRegisterType): Promise<any> => {
  const res = await axiosInstance.post('/members/check-register', params);
  return res.data;
};

export const checkInvitationCodeService = async (
  params: InvitationCodeType,
): Promise<InvitationResponseType> => {
  const res = await axiosInstance.post('/members/check-invitation', params);
  return res.data;
};

export const postShopRegister = async (params: string) => {
  const res = await axiosInstance.post(`shops/${params}/register`);
  return res.data;
};

export const verifyOtpService = async (
  params: VerifyOTPParamsTypes,
): Promise<void> => {
  const res = await axiosInstance.post(`shops/${params.code}/verify-otp`, params.otpCode);
  return res.data.data;
};

export const resendOtpService = async (params: string) => {
  const res = await axiosInstance.post(`shops/${params}/resend-otp`);
  return res.data;
};
