import { ConditionalListTypes, ForgotPasswordParamsTypes, LoginParamsTypes } from './type';

import axiosInstance from 'services/common/instance';
import { ShopDataTypes } from 'services/shop/type';

export const forgotPasswordService = async (params: ForgotPasswordParamsTypes): Promise<any> => {
  const res = await axiosInstance.post('/members/forgot-password', params);
  return res.data;
};

export const getInfoMemberService = async (code: string): Promise<ShopDataTypes> => {
  const res = await axiosInstance.post(`/members/${code}`);
  return res.data.data;
};

const loginEventService = async (params: LoginParamsTypes): Promise<any> => {
  const res = await axiosInstance.post('/members/login', params);
  return res.data;
};

export const getConditionalListService = async (): Promise<ConditionalListTypes> => {
  const res = await axiosInstance.post('members/conditions-list');
  return res.data.data;
};

export default loginEventService;
