/* eslint-disable */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import OtpPopup from '../OtpPopup';
import PolicyPopup from '../PolicyPopup';
import SuccessPopup from '../SuccessPopup';

import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Container from 'components/organisms/Container';
import Form from 'components/organisms/Form';
import useDebounceInput from 'hooks/useDebounceInput';
import { postShopRegister, resendOtpService, verifyOtpService } from 'services/register';
import getShopService from 'services/shop';
import mapModifiers from 'utils/functions';
import { registerSchema } from 'utils/schemas';

export type RegisterFormDataTypes = {
  code: string;
};
interface RegisterProps extends MienTypes {
  modifier?: 'popup';
  disabledLogin?: boolean;
  handleLogin?: () => void;
}

const Register: React.FC<RegisterProps> = ({
  modifier, mien, handleLogin
}) => {
  // const [errorMessage, setErrorMessage] = useState('');
  const [otpError, setOtpError] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const isSubmittingRef = useRef(false);
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [checkboxMessage, setCheckboxMessage] = useState('');
  const [checked, setChecked] = useState(true);
  const [validCode, setValidCode] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  // const [eventTime, setEventTime] = useState<string>();
  // const [eventTime2, setEventTime2] = useState<string>();
  const [openPopup, setOpenPopup] = useState({
    policy: false,
    otp: false,
    success: false,
  });

  const method = useForm<RegisterFormDataTypes>({
    resolver: yupResolver(registerSchema),
    mode: 'onChange',
    defaultValues: {
      code: '',
    },
  });

  const validateField = async (
    value: string,
  ) => {
    // setErrorMessage('');
    if (value) {
      const data: RegisterFormDataTypes = { code: value };
      try {
        const res = await getShopService(data.code);
        setPhone(res.phone);
        setName(res.name);
        setValidCode(true);
      } catch (error) {
        const errors = error as ErrorResponse[];

        if (Array.isArray(errors)) {
          console.log('errors: ', errors);

          errors.forEach((err) => {
            switch (err.code) {
              case 'E_1001':
                method.setError('code', {
                  message: 'Mã cửa hàng đã được đăng ký',
                  type: 'validate'
                });
                // setErrorMessage('Mã cửa hàng đã được đăng ký');
                break;
              case 404:
                method.setError('code', {
                  message: 'Mã cửa hàng không tồn tại',
                  type: 'validate'
                });
                // setErrorMessage('Mã cửa hàng không tồn tại');
                break;

              default:
                method.setError('code', {
                  message: 'Mã cửa hàng không chính xác!',
                  type: 'validate'
                });
                // setErrorMessage('Mã cửa hàng không chính xác!');
                break;
            }
          });
        } else {
          method.setError('code', {
            message: 'Mã cửa hàng không chính xác!',
            type: 'validate'
          });
          // setErrorMessage('Mã cửa hàng không chính xác!');
        }

        setPhone('');
        setName('');
        setValidCode(false);
      }
    }
  };

  const shopCodeDb = useDebounceInput(method.watch('code'), 500);
  useEffect(() => {
    validateField(shopCodeDb);
  }, [shopCodeDb]);

  useEffect(() => {
    if (openPopup.otp) {
      isSubmittingRef.current = false;
    }
  }, [openPopup.otp]);

  const handleSubmit = async (data: RegisterFormDataTypes) => {
    if (!validCode) return;
    if (isSubmittingRef.current) return;
    setIsLoading(true);

    try {
      isSubmittingRef.current = true;
      await postShopRegister(data.code);
      setOpenPopup((prevState) => ({
        ...prevState,
        otp: true,
      }));
    } catch (error) {
      isSubmittingRef.current = false;
      const errors = error as ErrorResponse[];
      if (Array.isArray(errors)) {
        errors.forEach((err) => {
          if (err.code === 'E_1001') {
            method.setError('code', {
              message: 'Mã cửa hàng đã được đăng ký',
              type: 'validate'
            });
          } else if (err.code === 'E_1002') {
            method.setError('code', {
              message: 'Gửi SMS không thành công',
              type: 'validate'
            });
            // setErrorMessage('Gửi SMS không thành công');
          } else {
            method.setError('code', {
              message: 'Có lỗi xảy ra! Vui lòng thử lại!',
              type: 'validate'
            });
            // setErrorMessage('Có lỗi xảy ra! Vui lòng thử lại!');
          }
        });
      } else {
        method.setError('code', {
          message: 'Có lỗi xảy ra! Vui lòng thử lại!',
          type: 'validate'
        });
        // setErrorMessage('Có lỗi xảy ra! Vui lòng thử lại!');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitOTP = async (val: string) => {
    try {
      setIsLoadingOtp(true);
      setOtpError('');
      await verifyOtpService({
        code: shopCodeDb,
        otpCode: {
          otp: val,
        },
      });
      // setEventTime(res.eventTime);
      // setEventTime2(res.text_show);
      setOpenPopup((prevState) => ({
        ...prevState,
        otp: false,
        success: true,
      }));
      method.reset({
        code: '',
      });
      setPhone('');
      setName('');
    } catch (error) {
      const errors = error as ErrorResponse[];
      if (Array.isArray(errors)) {
        errors.forEach((err) => {
          if (err.code === 404) {
            setOtpError('Mã OTP không chính xác! Vui lòng thử lại!');
          } else setOtpError('Có lỗi xảy ra! Vui lòng thử lại!');
        });
      } else setOtpError('Có lỗi xảy ra! Vui lòng thử lại!');
    } finally {
      setIsLoadingOtp(false);
    }
  };
  const handleResetOtp = async () => {
    await resendOtpService(shopCodeDb);
  };

  // Check policy checkbox status
  useEffect(() => {
    if (checked) {
      setValidCode(true);
      setCheckboxMessage('');
    } else {
      setValidCode(false);
      setCheckboxMessage('Vui lòng chọn đồng ý điều khoản chương trình!');
    }
  }, [checked]);

  useEffect(() => {
    if (openPopup.policy) {
      document.documentElement.classList.add('overflow');
    } else document.documentElement.classList.remove('overflow');
  }, [openPopup.policy]);

  return (
    <>
      <Container>
        <div className={mapModifiers('t-register', modifier)}>
          {modifier !== 'popup' && (
            <div className="t-register_heading">
              <Heading
                type="h2"
                modifiers={['40x56', 'uppercase', '700', 'center', 'linearMainText2025']}
              >
                Đăng Ký tham gia
              </Heading>
            </div>
          )}
          <div className="t-register_wrapper">
            <Form method={method} submitForm={handleSubmit}>
              <div className="t-register_formWrapper">
                <div className="t-register_notiWrap">
                  <div className="t-register_noti">
                    <Text modifiers={['center', '400', '36x48', 'yaleBlue']}>
                      Quý khách hàng vui lòng điền mã cửa hàng
                      được dán trên thiệp mời vào ô bên dưới
                    </Text>
                  </div>
                </div>
                <div className="t-register_formWrapper-field">
                  <Controller
                    name="code"
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        modifiers={['h60']}
                        id="code"
                        label="Nhập mã cửa hàng"
                        value={field.value}
                        error={fieldState?.error?.message}
                        // handleBlur={(e) => validateField(
                        //   e.currentTarget.value,
                        // )}
                        handleChange={field.onChange}
                      />
                    )}
                  />
                  {/* {
                    errorMessage !== ''
                    && (
                      <div className="t-register_error">
                        <Text modifiers={['center', '400', '24x28', 'red']}>
                          {errorMessage}
                        </Text>
                      </div>
                    )
                  } */}
                </div>
                <div className="t-register_notiWrap">
                  <div className="t-register_noti">
                    <Text modifiers={['center', '400', '36x48', 'yaleBlue']}>
                      Vui lòng kiểm tra thông tin tên cửa hàng và số điện thoại
                    </Text>
                  </div>
                </div>
                <div className="t-register_formWrapper-field t-register_formWrapper-field-mb">
                  <Input
                    id="name"
                    noCursor
                    value={name}
                    handleChange={(e) => setName(e.target.value)}
                    label="Tên cửa hàng"
                  />
                </div>
                <div className="t-register_formWrapper-field">
                  <Input
                    id="phone"
                    noCursor
                    value={phone}
                    handleChange={(e) => setPhone(e.target.value)}
                    label="Số điện thoại"
                  />
                </div>
                <div className="t-register_term">
                  <Text modifiers={['center', '400', '36x48', 'yaleBlue']}>
                    Nếu các thông tin trên chính xác, vui lòng nhấn vào nút ĐĂNG
                    KÝ dưới đây
                  </Text>
                  <div className="t-register_term_checkbox">
                    <Checkbox
                      id="term"
                      checked={checked}
                      name="term"
                      error={checkboxMessage}
                      onChange={() => setChecked(!checked)}
                    >
                      <Text modifiers={['center', '400', '36x48', 'yaleBlue']}>
                        Tôi đã đọc và đồng ý với điều khoản lưu trữ thông tin
                      </Text>
                    </Checkbox>
                  </div>
                  <div className="t-register_term-button button-2025">
                    <Button type="submit" disabled={isLoading || isSubmittingRef.current} modifiers="primary-2025" loading={isLoading}>Đăng ký</Button>
                  </div>
                </div>
                <div
                  onClick={() => setOpenPopup((prevState) => ({
                    ...prevState,
                    policy: true,
                  }))}
                  style={{ cursor: 'pointer', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}
                >
                  <Text
                    className="t-register_term-seemore"
                    modifiers={[
                      '400',
                      'center',
                      '36x48', 'yaleBlue', 'underline'
                    ]}
                  >
                    Xem thêm về điều khoản lưu trữ thông tin của chương trình
                  </Text>
                </div>
              </div>
            </Form>
            <div className="t-register_introduce">
              <div className="t-register_introduce-title">
                <Heading
                  type="h2"
                  modifiers={['40x56', 'uppercase', '700', 'center', 'linearBlue2025']}
                >
                  HƯỚNG DẪN ĐĂNG KÝ THAM GIA
                </Heading>
              </div>
              <ul className="t-register_introduce-wrapNote">
                <li className="t-register_introduce-note">
                  <Text modifiers={['400', '36x48', 'yaleBlue']}>
                    Nhập Mã cửa hàng và kiểm tra thông tin Tên cửa hàng/Số điện thoại.
                  </Text>
                </li>
                <li className="t-register_introduce-note">
                  <Text modifiers={['400', '36x48', 'yaleBlue']}>
                    Xem và xác nhận vào ô điều khoản lưu trữ thông tin của chương trình.
                    Sau đó nhấn nút ĐĂNG KÝ.
                  </Text>
                </li>
                <li className="t-register_introduce-note">
                  <Text modifiers={['400', '36x48', 'yaleBlue']}>
                    Nhập mã xác nhận được gửi qua tin nhắn số điện thoại để hoàn tất đăng ký.
                  </Text>
                </li>
                <li className="t-register_introduce-note">
                  <Text modifiers={['400', '36x48', 'yaleBlue']}>
                    Nhận thông tin đăng nhập bao gồm Tên truy cập và Mật khẩu
                    được gửi qua tin nhắn số điện thoại của Quý khách hàng.
                  </Text>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>

      <PolicyPopup
        isOpen={openPopup.policy}
        handleClose={() => setOpenPopup((prevState) => ({
          ...prevState,
          policy: false,
        }))}
      />
      <OtpPopup
        loading={isLoadingOtp}
        openPopup={openPopup.otp}
        error={otpError}
        phoneValue={phone}
        handleResend={handleResetOtp}
        handleSubmit={handleSubmitOTP}
        handleClose={() => setOpenPopup((prevState) => ({
          ...prevState,
          otp: false,
        }))}
      />
      <SuccessPopup
        openPopup={openPopup.success}
        mien={mien}
        handleClose={() => {
          setOpenPopup((prevState) => ({
            ...prevState,
            success: false,
          }));
          if (modifier === 'popup' && handleLogin) {
            handleLogin();
          }
        }}
      />
    </>
  );
};

Register.defaultProps = {
  modifier: undefined,
  handleLogin: undefined,
};

export default Register;
