import { OptionType } from 'components/molecules/Pulldown';

export const LOCALSTORAGE_FIELDNAME = {
  CURRENT_TIME: 'ES24_FoyerCurrentTime',
  FOYER_STEP: 'ES24_Foyer_Step',
  FOYER_DONE: 'ES24_Foyer_Done',
  FOYER_BOOTH: 'ES24_tracking_foyer_booth',
  INTERACTIVE_ANSWER: 'ES24_Interactive_Answer',
  HISTORY_AREA: 'ES24_HISTORY_AREA',
};
export const GA_ID = 'UA-224063231-1';
export const WS_ECHO_KEY = 'one-event';
export const LIVE_URL = 'https://cdn-mecury.3forcom.org/live/ensure-2024.m3u8';
const genderOptions: OptionType[] = [
  {
    label: 'Nam',
    value: '1',
  },
  {
    label: 'Nữ',
    value: '2',
  },
];

export const TOTAL_TIME_INTERACTIVE = 30;
export const TIME_DELAY_INTERACTIVE = 10;

// timecode
// 2:58
// 6:52
// 11:47
// 18:37

export default genderOptions;
