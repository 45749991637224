/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-danger */
import React, { useRef, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import Interactive from './Interactive';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Pulldown, { OptionType } from 'components/molecules/Pulldown';
import Form from 'components/organisms/Form';
import LiveComment, { LiveStreamQuestionType } from 'components/organisms/LiveComment';
import TfcPlayer, { TFCPlayerRef } from 'components/organisms/TfcPlayer';

export type QuestionForm = {
  speaker?: OptionType | null;
  question: string;
};

interface LivestreamProps {
  method: UseFormReturn<QuestionForm>;
  handleSubmit: (data: QuestionForm) => void;
  speakerOptions?: OptionType[];
  liveUrl: string;
  language?: string;
  isLoading?: boolean;
  liveQuestions?: LiveStreamQuestionType[];
  handleEndLive?: () => void;
}

const Livestream: React.FC<LivestreamProps> = (
  {
    method, speakerOptions, liveUrl, language = 'vi', liveQuestions, isLoading,
    handleSubmit, handleEndLive,
  },
) => {
  const player = useRef<TFCPlayerRef>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  return (
    <div className={`t-livestream${isFullscreen ? ' fullscreen' : ''}`}>
      {/* VIDEO LIVESTREAM  */}
      <div className="t-livestream_video">
        <TfcPlayer
          ref={player}
          src={liveUrl}
          audioLang={language}
          handleEnd={handleEndLive}
        // handleTimeUpdate={(time) => {
        //   if (time > 60 && !canMinimize) {
        //     setCanMinimize(true);
        //     setIsFullscreen(false);
        //   }
        // }}
        />
        <div
          className="t-livestream_fullscreen"
          onClick={() => setIsFullscreen(!isFullscreen)}
        >
          <Icon iconName={!isFullscreen ? 'fullscreen' : 'minimize'} iconSize="32x32" />
        </div>
      </div>
      {/* COMMENTS  */}
      <div className="t-livestream_comments">
        <LiveComment commentList={liveQuestions} />
      </div>
      {/* FORM  */}
      <Form method={method} submitForm={handleSubmit}>
        <div className="t-livestream_control">
          <div className="t-livestream_control_wrapper">
            <div className="t-livestream_control_pulldown">
              <Controller
                name="speaker"
                control={method.control}
                render={({ field, fieldState }) => (
                  <Pulldown
                    {...field}
                    isError={!!fieldState.error}
                    placeholder="Chọn diễn giả để trả lời"
                    options={speakerOptions || []}
                    name="speaker"
                    error={fieldState.error?.message}
                  />
                )}
              />
            </div>
            {/* submit question  */}
            <div className="t-livestream_action">
              <div className="t-livestream_action_button">
                <Button size="h44" type="submit" loading={isLoading}>
                  GỬI CÂU HỎI
                </Button>
              </div>
              <div
                className={`t-livestream_action_textarea ${method.formState.errors.question ? 'error' : ''
                  }`}
              >
                <textarea
                  rows={3}
                  placeholder="Đặt câu hỏi"
                  {...method.register('question')}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
      <Interactive />
    </div>
  );
};

Livestream.defaultProps = {
  speakerOptions: undefined,
  language: undefined,
  handleEndLive: undefined,
  liveQuestions: undefined,
  isLoading: undefined,
};

export default Livestream;
