import React, { useEffect } from 'react';

import LoginTemplate from 'components/templates/Login';
import EventWaiting from 'pages/EventWaiting';
import { setLocalStorageArea } from 'services/common/storage';
import { useAppSelector } from 'store/hooks';

interface LoginPageProps extends MienTypes { }

const Login: React.FC<LoginPageProps> = ({ mien }) => {
  const dataConfig = useAppSelector((state) => state.system.data);
  const userInfo = useAppSelector((state) => state.profile.data);
  const userLogin = useAppSelector((state) => state.user.data);

  useEffect(() => {
    setLocalStorageArea(mien);
  }, []);

  return (
    <div className="p-login">
      {dataConfig?.redirectToPage === 'WAITING_2' && (userInfo || userLogin) ? <EventWaiting /> : <LoginTemplate mien={mien} />}
    </div>
  );
};

export default Login;
