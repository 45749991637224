/* eslint-disable */
import React, { useMemo, useRef } from 'react';

import abbottLogo from 'assets/images/new2025/abbott.png';
import yduoc from 'assets/images/new2025/yduoc.png';
// import bannerMobileImg from 'assets/images/new/home/banner-mb.jpg';
// import bannerImg from 'assets/images/new/home/banner.jpg';
import Image from 'components/atoms/Image';
import useWindowDimensions from 'hooks/useWindowDimension';
import mapModifiers from 'utils/functions';
import { he } from 'date-fns/locale';

interface BannerProps {
  customContent?: boolean;
  isCenter?: boolean;
  children?: React.ReactNode;
  bannerText?: {
    mobile: string;
    desktop: string;
  };
  isFullHeight?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customContent, isCenter, children, bannerText, isFullHeight
}) => {
  const { width, height } = useWindowDimensions();
  const firstHeightRef = useRef(0)

  const fullHeight = useMemo(() => {
    if (isFullHeight) {
      return true;
    }
    if (width && height) {
      if (width > 1024 && width > height) {
        return true;
      }
      if (width <= 1024 && width >= 768 && width > height) {
        return true;
      }
      if (width < 768 && width > height) {
        return true;
      }
      return false;
    }
    return false;
  }, [width, height, isFullHeight]);

  const firstHeight = useMemo(() => {
    if (width && firstHeightRef.current !== width) {
      firstHeightRef.current = width;
      return height;
    }
  }, [width, height]);

  return (
    <div className={mapModifiers('t-banner', customContent && 'custom', isCenter && 'isCenter', isFullHeight ? 'fullheight' : '')}>
      {/* Background  */}
      <div className="t-banner_background" style={fullHeight ? { height: `${firstHeight}px` } : undefined}>
        {
          !customContent ? (
            <div className="t-banner_wrapper">
              <div className="t-banner_relative">
                {/* Logo  */}
                <div className="t-banner_logo">
                  <div className="t-banner_logo_item">
                    <Image src={yduoc} ratio="1x1" />
                  </div>
                  <div className="t-banner_logo_item">
                    <Image src={abbottLogo} ratio="153x174" />
                  </div>
                </div>
                {children}
              </div>
            </div>
          ) : children
        }
      </div>
    </div>
  );
};

Banner.defaultProps = {
  customContent: undefined,
};

export default Banner;
