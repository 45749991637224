/* eslint-disable */
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import footer from 'assets/images/new2025/footer_select.png';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import eventConfig from 'configs';
import { KeyRoute, returnPath } from 'helpers/redirectToPage';
import useWindowDimensions from 'hooks/useWindowDimension';
import { getTokenSession } from 'services/common/storage';
import { useAppSelector } from 'store/hooks';

const Select: React.FC = () => {
  const dataConfig = useAppSelector((state) => state.system.data);
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const curToken = getTokenSession();

  const navigateArea = useCallback((type: 'MN' | 'MBMT') => {
    if (dataConfig) {
      if (dataConfig.redirectToPage === 'REGISTER') {
        if (type === 'MN') {
          navigate(eventConfig.SLUG_PARAMS.MN);
        } else {
          navigate(eventConfig.SLUG_PARAMS.MBMT);
        }
      } else {
        if (curToken) {
          navigate(returnPath(dataConfig.redirectToPage as KeyRoute));
        } else if (type === 'MN') {
          navigate(eventConfig.SLUG_PARAMS.LOGIN_MIENNAM);
        } else {
          navigate(eventConfig.SLUG_PARAMS.LOGIN_MIENBACTRUNG);
        }
      }
    }

    // if (dataConfig && (dataConfig.redirectToPage === 'WAITING_1' || dataConfig.redirectToPage === 'WAITING_2')) {
    //   if (curToken) {
    //     navigate(returnPath(dataConfig.redirectToPage as KeyRoute));
    //   } else if (type === 'MN') {
    //     navigate(eventConfig.SLUG_PARAMS.LOGIN_MIENNAM);
    //   } else {
    //     navigate(eventConfig.SLUG_PARAMS.LOGIN_MIENBACTRUNG);
    //   }
    // } else if (type === 'MN') {
    //   navigate(eventConfig.SLUG_PARAMS.MN);
    // } else {
    //   navigate(eventConfig.SLUG_PARAMS.MBMT);
    // }
  }, [dataConfig]);

  return (
    <div className="p-select" style={{ height: `${height}px` }}>
      <div className="p-select_wrapper">
        <div className="p-select_title">
          <Text type="span" modifiers={['linearGold2025', '40x48', '700', 'center']}>Chương Trình Hội Thảo Khoa Học</Text>
          <div className="decor-line" />
        </div>
        <div className="p-select_content">
          <Text modifiers={['linearMain2025', 'brandonGrotesque', '72x86', 'center', '900']}>
            ĐÀO TẠO TƯ VẤN
          </Text>
          <Text modifiers={['linearMain2025', 'brandonGrotesque', '72x86', 'center', '900']}>
            DINH DƯỠNG CHO NGƯỜI LỚN TUỔI
          </Text>
          <Text modifiers={['linearMain2025', 'brandonGrotesque', '72x86', 'center', '900']}>
            VÀ NGƯỜI MẮC ĐÁI THÁO ĐƯỜNG
          </Text>
        </div>
        <Text modifiers={['linearBlue2025', '52x70', '500', 'center']}>Vui lòng chọn khu vực của cửa hàng</Text>
        <div className="p-select_buttons">
          <div className="p-select_button" onClick={() => navigateArea('MN')}>
            <Text modifiers={['linearBlueBold2025', '40x48', '700', 'center']}>
              Khu vực
            </Text>
            <Text modifiers={['linearBlueBold2025', '40x48', '700', 'center']}>
              Miền Nam
            </Text>
          </div>
          <div className="p-select_button" onClick={() => navigateArea('MBMT')}>
            <Text modifiers={['linearBlueBold2025', '40x48', '700', 'center']}>
              Khu vực
            </Text>
            <Text modifiers={['linearBlueBold2025', '40x48', '700', 'center']}>
              Miền Bắc và Miền Trung
            </Text>
          </div>
        </div>
      </div>
      <div className="p-select_footer">
        <Image ratio="1920x221" src={footer} alt="footer" />
        <div className="p-select_footer_code">
          <Text modifiers={['500', 'black', '18x24', 'right']}>
            COR-C-127-25
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Select;
