import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import eventConfigs from '../../../configs';
import BackgroundPanel from '../BackgroundPanel';

import checkImg from 'assets/images/checkTick.png';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import { Radio } from 'components/atoms/Radio';
import Text from 'components/atoms/Text';
import BackgroundTitle from 'components/molecules/BackgroundTitle';
import Rating from 'components/molecules/Rating';
import Form from 'components/organisms/Form';
import { postSurveyAnswerService } from 'services/survey';
import {
  SurveyAnswerParams,
  SurveyQuestionDataTypes,
} from 'services/survey/type';

interface SurveyReadyProps {
  handleClick?: () => void;
}

interface SurveyProps {
  surveyQuestions: SurveyQuestionDataTypes[];
  handleNextStep?: (step: number) => void
}

export const SurveyReady: React.FC<SurveyReadyProps> = ({ handleClick }) => (
  <BackgroundPanel
    modifier="firstSurvey"
    title="Khảo Sát ý kiến"
  >
    <div className="t-survey_ready">
      <Text modifiers={['24x32', 'pattrickBlue', '500', 'center']}>
        Nhằm hỗ trợ Ban Tổ Chức không ngừng cải thiện chất lượng các buổi hội thảo trong tương lai,
        <br />
        mời Quý Chuyên viên Y Tế vui lòng dành thời gian thực hiện các câu hỏi khảo sát đánh giá.
      </Text>
      <div className="t-survey_finished_button">
        <Button size="h44" onClick={handleClick}>THAM GIA KHẢO SÁT Ý KIẾN</Button>
      </div>
    </div>
  </BackgroundPanel>

);
export const SurveyFinished: React.FC = () => {
  const navigate = useNavigate();

  return (
    <BackgroundPanel modifier="endSurvey" title="Khảo sát ý kiến">
      <div className="t-survey_finished">
        <div className="t-survey_image">
          <Image src={checkImg} alt="stick" ratio="1x1" />
        </div>
        <div>
          <Text modifiers={['24x32', '500', 'pattrickBlue', 'center']}>
            Chân thành cảm ơn Quý Chuyên viên Y Tế đã tham gia khảo sát ý kiến cho chương trình.
            <br />
            Quý vị hãy tiếp tục tham gia kiểm tra kiến thức nhận Chứng chỉ đào tạo y khoa liên tục.
          </Text>
        </div>
        <div className="t-survey_finished_button">
          <Button
            size="h44"
            onClick={() => {
              navigate(eventConfigs.SLUG_PARAMS.CME);
            }}
          >
            KIỂM TRA KIẾN THỨC
          </Button>
        </div>
      </div>
    </BackgroundPanel>
  );
};
const Survey: React.FC<SurveyProps> = ({
  surveyQuestions,
  handleNextStep = () => { },
}) => {
  const [error, setError] = useState('');
  const [radioSelect, setRadioSelect] = useState(0);
  const [ratingVal, setRatingVal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSurvey, setCurrentSurvey] = useState(0);

  const method = useForm<any>();

  /**
   * Submit Answer and next question
   */
  const handleNextQuestion = async (data: SurveyAnswerParams) => {
    setIsLoading(true);
    try {
      await postSurveyAnswerService(data);
      setCurrentSurvey(currentSurvey + 1);
      if (currentSurvey === (surveyQuestions.length - 1)) {
        handleNextStep(2);
      } else {
        setCurrentSurvey(currentSurvey + 1);
      }
    } catch {
      toast.error('Gửi câu trả lời thất bại !!', {
        position: 'top-right',
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };
  /**
   * RENDER SURVEY TEMPLATE
   */
  const renderTemplate = () => {
    /* ------------Switch definition value------------ */
    // 1: Rating with star
    // 2: Textarea
    // 3: Radio with flex
    // 4: Normal radio
    // --------------------------------------------
    switch (surveyQuestions[currentSurvey]?.type) {
      case 1:
        return (
          <div className="t-survey_rating">
            <div className="t-survey_rating_text">
              <span>
                Rất không
                {' '}
                <br />
                {' '}
                hài lòng
              </span>
              <span>Rất hài lòng</span>
            </div>
            <Rating
              isShowText={currentSurvey === 5}
              starsSelected={ratingVal || 0}
              handleClick={(val) => {
                setRatingVal(val + 1);
                setError('');
              }}
            />
          </div>
        );
      case 2:
        return (
          <div className="t-survey_textarea">
            <textarea
              rows={5}
              placeholder="Quý Chuyên viên Y Tế vui lòng chia sẻ ý kiến tại đây"
              {...method.register('comment_1')}
            />
          </div>
        );

      case 3:
        return (
          <div className="t-survey_answers center">
            <ul className="answer type3 flex sm">
              {surveyQuestions[currentSurvey].answers?.map((item, idx) => (
                <li key={`${surveyQuestions[currentSurvey].id}-${item.id}${idx.toString()}`}>
                  <Radio
                    vertical
                    onChange={() => {
                      setRadioSelect(item.id);
                      setError('');
                    }}
                    value={item.id}
                    checked={radioSelect === item.id}
                    name="type3"
                  >
                    {item.content && item.content.toString()}
                  </Radio>
                </li>
              ))}
            </ul>
          </div>
        );
      case 4:
        return (
          <div className="t-survey_answers center">
            <ul className="answer sm">
              {surveyQuestions[currentSurvey].answers?.map((item, idx) => (
                <li key={`${surveyQuestions[currentSurvey].id}-${item.id}${idx.toString()}`}>
                  <Radio
                    onChange={() => {
                      setRadioSelect(item.id);
                      setError('');
                    }}
                    value={item.id}
                    checked={radioSelect === item.id}
                    name="type3"
                  >
                    {item.content && item.content.toString()}
                  </Radio>
                </li>
              ))}
            </ul>
          </div>
        );

      default:
        return null;
    }
  };
  /* ------------Submit form------------ */
  const handleSubmitForm = (data: any) => {
    const question = surveyQuestions[currentSurvey];

    switch (question.type) {
      case 1:
        if (ratingVal === 0) {
          setError('Vui lòng đánh giá !!');
        } else {
          const answer2: SurveyAnswerParams = {
            survey_question_id: question.id,
            survey_answer_content: {
              type: question.type,
              vote: ratingVal,
            },
          };
          if (handleNextQuestion) {
            handleNextQuestion(answer2);
            setRatingVal(0);
          }
        }
        break;
      case 2:
        if (data) {
          const answer1: SurveyAnswerParams = {
            survey_question_id: question.id,
            survey_answer_content: {
              type: question.type,
              comment_1: data.comment_1 ? data.comment_1 : '',
            },
          };
          if (handleNextQuestion) {
            handleNextQuestion(answer1);
            method.setValue('comment_1', '');
          }
        }
        break;
      case 3:
        if (radioSelect === 0 && question.isRequiredAnswer) {
          setError('Vui lòng chọn đáp án !!');
        } else {
          const answer3: SurveyAnswerParams = {
            survey_question_id: question.id,
            survey_answer_content: {
              type: question.type,
              answers: [radioSelect],
            },
          };

          if (handleNextQuestion) {
            handleNextQuestion(answer3);
            setRadioSelect(0);
          }
        }
        break;
      case 4:
        if (radioSelect === 0 && question.isRequiredAnswer) {
          setError('Vui lòng chọn đáp án !!');
        } else {
          const answer4: SurveyAnswerParams = {
            survey_question_id: question.id,
            survey_answer_content: {
              type: question.type,
              answers: [radioSelect],
            },
          };
          if (handleNextQuestion) {
            handleNextQuestion(answer4);
            setRadioSelect(0);
          }
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="t-survey">
      {((surveyQuestions[currentSurvey]?.content
        && surveyQuestions[currentSurvey]?.subContent)
        || (surveyQuestions[currentSurvey]?.content
          && !surveyQuestions[currentSurvey]?.subContent)) && (
          <div className="t-survey_wrapTitle">
            <div className="t-survey_title">
              <Heading
                type="h2"
                modifiers={[
                  '40x56',
                  '700',
                  'pattrickBlue',
                  'center',
                ]}
                content={surveyQuestions[currentSurvey]?.content}
              />
            </div>
          </div>
        )}
      {surveyQuestions[currentSurvey]?.subContent
        && (
          <div className="t-survey_question">
            <BackgroundTitle modifier="survey" content={surveyQuestions[currentSurvey]?.subContent} />
          </div>
        )}
      <div className={`t-survey_form type-${surveyQuestions[currentSurvey]?.type}`}>
        <Form method={method} submitForm={handleSubmitForm}>
          {renderTemplate()}
          {error && <p className="t-survey_form_error">{error}</p>}
          {currentSurvey !== surveyQuestions.length && (
            <div className="t-survey_button">
              <Button type="submit" size="h56" loading={isLoading}>
                {currentSurvey !== surveyQuestions.length - 1
                  ? 'TIẾP THEO'
                  : 'HOÀN THÀNH'}
              </Button>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

SurveyReady.defaultProps = {
  handleClick: undefined,
};

export default Survey;
