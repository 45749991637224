// import bsThinh from 'assets/images/doctors/bs-thinh.png';
import bsTuan from 'assets/images/new2025/testimonials/bstsNguyenAnhTuan.png';
import bsPhi from 'assets/images/new2025/testimonials/tsbsDaoThiYenPhi.png';
import tsbsLeMaiTraMi from 'assets/images/new2025/testimonials/tsbsLeMaiTraMi.png';
import tsbsNguyenThuyLinh from 'assets/images/new2025/testimonials/tsbsNguyenThuyLinh.png';
import bsChi from 'assets/images/new2025/testimonials/tsbsTranThiKimChi.png';
import { OptionType } from 'components/molecules/Pulldown';
import { AgendaTypes } from 'components/templates/Agenda';
import { LivestreamTypes } from 'components/templates/Questions';
import { TestimonialsDataType } from 'components/templates/Testimonials';

export const doctorOptions: OptionType[] = [
  {
    value: '1',
    label: 'TS.BS. LƯU NGÂN TÂM',
  },
  {
    value: '2',
    label: 'PGS.TS.BS. LÊ THỊ TUYẾT LAN',
  },
  {
    value: '3',
    label: 'TS.BS. NGUYỄN HỮU QUÂN',
  },
];

const doctorData: TestimonialsDataType[] = [
  {
    id: 1,
    name: 'PGS. TS. BS. NGUYỄN ANH TUẤN',
    role: '',
    img: bsTuan,
    position: [
      'Giám đốc Trung tâm Đào tạo nhân lực y tế',
      'theo nhu cầu xã hội - Đại học Y Dược TP.HCM',
    ],
  },
  {
    id: 2,
    name: 'TS. BS. ĐÀO THỊ YẾN PHI',
    role: '',
    img: bsPhi,
    position: [
      'Nguyên Trưởng bộ môn Dinh dưỡng và An toàn Thực phẩm',
      'Trường Đại học Y khoa Phạm Ngọc Thạch TP.HCM',
    ],
  },
  {
    id: 2,
    name: 'ThS. BS. CK2. TRẦN THỊ KIM CHI',
    role: '',
    img: bsChi,
    position: ['Trưởng khoa Dinh Dưỡng ', 'Bệnh viện Nhân Dân Gia Định TP.HCM'],
  },
];

export const doctorDataMienBac: TestimonialsDataType[] = [
  {
    id: 1,
    name: 'PGS. TS. BS. NGUYỄN THÙY LINH',
    role: '',
    img: tsbsNguyenThuyLinh,
    position: [
      'Phó trưởng Bộ môn Dinh dưỡng và ATTP, Trường Đại Học Y Hà Nội,',
      'Trưởng khoa Dinh dưỡng & Tiết chế,',
      'Bệnh viện Đại học Y Hà Nội',
    ],
  },
  {
    id: 2,
    name: 'ThS. BS. LÊ MAI TRÀ MI',
    role: '',
    img: tsbsLeMaiTraMi,
    position: [
      'Bác sĩ Khoa Dinh dưỡng & Tiết chế, Bệnh viện Đại học Y Hà Nội',
      'Giảng viên thỉnh giảng Bộ môn Dinh dưỡng',
      'và An toàn thực phẩm, Đại học Y Hà Nội',
    ],
  },
];

export const agendaData: AgendaTypes[] = [
  {
    time: '13:00 - 13:10 ',
    title: 'Phát biểu mở đầu',
    subTitle: ['TÌNH HÌNH SỨC KHOẺ NGƯỜI LỚN TUỔI Ở VIỆT NAM'],
    subTitleMb: ['TÌNH HÌNH SỨC KHOẺ NGƯỜI LỚN TUỔI Ở VIỆT NAM'],
    speaker: ['PGS. TS. BS. Nguyễn Anh Tuấn'],
  },
  {
    time: '13:10 - 13:50',
    title: 'Chuyên đề 1',
    subTitle: [
      'MỘT SỐ VẤN ĐỀ SỨC KHỎE THƯỜNG GẶP',
      'VÀ CHĂM SÓC DINH DƯỠNG',
      'CHO NGƯỜI LỚN TUỔI'
    ],
    subTitleMb: ['MỘT SỐ VẤN ĐỀ SỨC KHỎE THƯỜNG GẶP VÀ CHĂM SÓC DINH DƯỠNG CHO NGƯỜI LỚN TUỔI'],
    speaker: ['TS. BS. Đào Thị Yến Phi'],
  },
  {
    time: '13:50 - 13:55',
    title: 'Giải lao - Hoạt động câu đố kiến thức',
  },
  {
    time: '13:55 - 14:35',
    title: 'Chuyên đề 2',
    subTitle: [
      'VAI TRÒ CỦA DINH DƯỠNG VÀ CAN THIỆP',
      'DINH DƯỠNG CHO NGƯỜI BỆNH',
      'ĐÁI THÁO ĐƯỜNG'
    ],
    subTitleMb: ['VAI TRÒ CỦA DINH DƯỠNG VÀ CAN THIỆP DINH DƯỠNG CHO NGƯỜI BỆNH ĐÁI THÁO ĐƯỜNG'],
    speaker: ['ThS. BS. CK2. Trần Thị Kim Chi'],
  },
];

export const agendaData2: AgendaTypes[] = [
  {
    time: '14:35 - 14:40',
    title: 'Giải lao - Hoạt động câu đố kiến thức',
  },
  {
    time: '14:40 - 15:10',
    title: 'Chuyên đề 3',
    subTitle: [
      'GIẢI PHÁP DINH DƯỠNG GIÚP CHĂM SÓC',
      'SỨC KHOẺ NGƯỜI LỚN TUỔI &',
      'NGƯỜI MẮC ĐÁI THÁO ĐƯỜNG',
    ],
    subTitleMb: ['GIẢI PHÁP DINH DƯỠNG GIÚP CHĂM SÓC SỨC KHOẺ NGƯỜI LỚN TUỔI & NGƯỜI MẮC ĐÁI THÁO ĐƯỜNG'],
    speaker: ['Đại diện Phòng Đào Tạo, Abbott Việt Nam'],
  },
  {
    time: '15:10 - 15:30',
    title: 'Thảo luận và tổng kết',
  },
  {
    time: 'Sau 15:30',
    title: 'Bài kiểm tra cấp chứng nhận',
    speaker: [
      'Dành cho các cá nhân có thời lượng',
      'tham dự đủ <strong>80% thời lượng nội dung</strong> giảng dạy',
    ],
  },
];

export const agendaDataMienBac: AgendaTypes[] = [
  {
    time: '13:00 - 13:10',
    title: 'Phát biểu mở đầu',
    subTitle: ['TÌNH HÌNH SỨC KHOẺ NGƯỜI LỚN TUỔI Ở VIỆT NAM'],
    subTitleMb: ['TÌNH HÌNH SỨC KHOẺ NGƯỜI LỚN TUỔI Ở VIỆT NAM'],
    speaker: ['PGS. TS. BS. Nguyễn Thùy Linh'],
  },
  {
    time: '13:10 - 13:50',
    title: 'Chuyên đề 1',
    subTitle: [
      'MỘT SỐ VẤN ĐỀ SỨC KHỎE THƯỜNG GẶP',
      'VÀ DINH DƯỠNG CHO NGƯỜI LỚN TUỔI',
    ],
    subTitleMb: ['MỘT SỐ VẤN ĐỀ SỨC KHỎE THƯỜNG GẶP VÀ DINH DƯỠNG CHO NGƯỜI LỚN TUỔI'],
    speaker: ['ThS. BS. Lê Mai Trà Mi'],
  },
  {
    time: '13:50 - 13:55',
    title: 'Giải lao - Hoạt động câu đố kiến thức',
  },
  {
    time: '13:55 - 14:35',
    title: 'Chuyên đề 2',
    subTitle: [
      'VAI TRÒ CỦA DINH DƯỠNG VÀ CHĂM SÓC',
      'DINH DƯỠNG CHO NGƯỜI BỆNH',
      'ĐÁI THÁO ĐƯỜNG',
    ],
    subTitleMb: ['ĐÁI THÁO ĐƯỜNG & NHỮNG HƯỚNG DẪN VỀ DINH DƯỠNG GIÚP CHĂM SÓC SỨC KHỎE CHO NGƯỜI MẮC ĐÁI THÁO ĐƯỜNG'],
    speaker: ['PGS. TS. BS. Nguyễn Thùy Linh'],
  },
];

export const agendaDataMienBac2: AgendaTypes[] = [
  {
    time: '14:35 - 14:40',
    title: 'Giải lao - Hoạt động câu đố kiến thức',
  },
  {
    time: '14:40 - 15:10',
    title: 'Chuyên đề 3',
    subTitle: [
      'GIẢI PHÁP DINH DƯỠNG GIÚP CHĂM SÓC',
      'SỨC KHOẺ NGƯỜI LỚN TUỔI &',
      'NGƯỜI MẮC ĐÁI THÁO ĐƯỜNG',
    ],
    subTitleMb: ['GIẢI PHÁP DINH DƯỠNG GIÚP CHĂM SÓC SỨC KHOẺ NGƯỜI LỚN TUỔI & NGƯỜI MẮC ĐÁI THÁO ĐƯỜNG'],
    speaker: ['Đại diện Phòng Đào Tạo, Abbott Việt Nam'],
  },
  {
    time: '15:10 - 15:30',
    title: 'Thảo luận và tổng kết',
  },
  {
    time: 'Sau 15:30',
    title: 'Bài kiểm tra cấp chứng nhận',
    speaker: [
      'Dành cho các cá nhân có thời lượng',
      'tham dự đủ <strong>80% thời lượng nội dung</strong> giảng dạy',
    ],
  },
];

export const livestreamData: LivestreamTypes[] = [
  {
    numberQuestion: '1',
    title:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    numberAreed: '',
  },
  {
    numberQuestion: '1',
    title:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    numberAreed: '2034',
  },
  {
    numberQuestion: '2',
    title:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    numberAreed: '',
  },
  {
    numberQuestion: '2',
    title:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    numberAreed: '2034',
  },
  {
    numberQuestion: '3',
    title:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    numberAreed: '',
  },
  {
    numberQuestion: '3',
    title:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
    numberAreed: '2034',
  },
];

export default doctorData;
