import 'App.scss';

import React from 'react';
import { Provider } from 'react-redux';
import {
  Navigate,
  Route,
  RouteProps,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import eventConfig from 'configs';
import MainLayoutContainer from 'containers/mainLayout';
import AppInitializer from 'helpers/appinitializer';
import Cme from 'pages/Cme';
import EventWaiting from 'pages/EventWaiting';
import Foyer from 'pages/Foyer';
import Home from 'pages/Home';
import Livestream from 'pages/Livestream';
import Login from 'pages/Login';
import Select from 'pages/Select';
import Survey from 'pages/Survey';
import Thanks from 'pages/Thanks';
import Tvc from 'pages/Tvc';
import { getLocalStorageArea, getTokenSession } from 'services/common/storage';
import { store } from 'store';

const routes = [
  {
    paths: '/',
    element: <Select />,
    isAuth: false,
  },
  {
    paths: '/logout',
    element: <div />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.LOGIN_MIENNAM,
    element: <Login mien="south" />,
    isAuth: false,
  },
  {
    paths: eventConfig.SLUG_PARAMS.LOGIN_MIENBACTRUNG,
    element: <Login mien="north" />,
    isAuth: false,
  },
  // {
  //   paths: eventConfig.SLUG_PARAMS.WAITING,
  //   element: <Waiting />,
  //   isAuth: true,
  // },
  {
    paths: eventConfig.SLUG_PARAMS.EVENTWAITING,
    element: <EventWaiting />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.TVC,
    element: <Tvc />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.LIVESTREAM,
    element: <Livestream />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.SURVEY,
    element: <Survey />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.CME,
    element: <Cme />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.FOYER,
    element: <Foyer />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.THANKS,
    element: <Thanks />,
    isAuth: false,
  },
];

interface PrivateRouteProps extends RouteProps {
  outlet: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ outlet }) => {
  const token = getTokenSession();

  if (!token) {
    const historyArea = getLocalStorageArea();
    let routeNav = eventConfig.SLUG_PARAMS.HOME;
    if (historyArea) {
      if (historyArea === 'south') {
        routeNav = eventConfig.SLUG_PARAMS.LOGIN_MIENNAM;
      } else {
        routeNav = eventConfig.SLUG_PARAMS.LOGIN_MIENBACTRUNG;
      }
    }
    return <Navigate to={routeNav} />;
  }

  return outlet;
};

const App: React.FC = () => (
  <Routes>
    <Route element={<MainLayoutContainer />}>
      {routes.map((item, index) => (item.isAuth ? (
        <Route
          path={item.paths}
          key={`route-${index.toString()}`}
          element={<PrivateRoute outlet={item.element} />}
        />
      ) : (
        <Route
          key={`router-${item.element}-${index.toString()}`}
          path={item.paths}
          element={item.element}
        />
      )))}
      <Route path="/miennam" element={<Home mien="south" />} />
      <Route path="/mienbacmientrung" element={<Home mien="north" />} />
    </Route>
  </Routes>
);
const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <Router>
      <AppInitializer />
      <App />
    </Router>
  </Provider>
);

export default AppWrapper;
