import React from 'react';
import { Container } from 'react-bootstrap';

import abbottLogo from 'assets/images/new2025/abbott-logo-black.png';
import logoBenhVienDaiHocYHN from 'assets/images/new2025/logoBenhVienDaiHocYHN.png';
import yduoc from 'assets/images/new2025/yduoc.png';
import Text from 'components/atoms/Text';
import Banner from 'components/templates/Banner';
import { useAppSelector } from 'store/hooks';

const EventWaiting: React.FC = () => {
  const profile = useAppSelector((state) => state.profile.data);

  return (
    <Banner customContent isFullHeight>
      <Container>
        <div className="p-eventWaiting_content">
          <div className="p-eventWaiting_logo">
            <div className="p-eventWaiting_logo_item">
              <img
                src={profile?.area === 'south' ? yduoc : logoBenhVienDaiHocYHN}
                alt="logo"
              />
            </div>
            <div className="p-eventWaiting_logo_item p-eventWaiting_logo_item-abbott">
              <img src={abbottLogo} alt="logo" />
            </div>
            {/* <div className="p-eventWaiting_logo_item">
            <img src={logo3a} alt="logo" />
          </div> */}
          </div>
          <div className="p-eventWaiting_title">
            <Text type="span" modifiers={['linearGold2025', '40x48', '700', 'center']}>
              Chương Trình Hội Thảo Khoa Học
            </Text>
            <div className="decor-line" />
          </div>
          <div className="p-eventWaiting_content2">
            <Text modifiers={['linearMain2025', 'brandonGrotesque', '52x70', 'center', '900']}>
              ĐÀO TẠO TƯ VẤN
            </Text>
            <Text modifiers={['linearMain2025', 'brandonGrotesque', '52x70', 'center', '900']}>
              DINH DƯỠNG CHO NGƯỜI LỚN TUỔI
            </Text>
            <Text modifiers={['linearMain2025', 'brandonGrotesque', '52x70', 'center', '900']}>
              VÀ NGƯỜI MẮC ĐÁI THÁO ĐƯỜNG
            </Text>
          </div>
          <div className="p-eventWaiting_time">
            <Text type="p" modifiers={['darkCerulean', 'center', '28x40', '600']}>
              SẼ BẮT ĐẦU VÀO LÚC 13 GIỜ
            </Text>
            <Text type="p" modifiers={['darkCerulean', 'center', '28x40', '600']}>
              XIN QUÝ KHÁCH HÀNG VUI LÒNG CHỜ TRONG GIÂY LÁT
            </Text>
          </div>
        </div>
      </Container>
    </Banner>
  );
};

export default EventWaiting;
