/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { RegisterFormDataTypes } from '../Register';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Form from 'components/organisms/Form';
import useDebounceInput from 'hooks/useDebounceInput';
import { forgotPasswordService, getInfoMemberService } from 'services/auth';
import getShopService from 'services/shop';
import { registerSchema } from 'utils/schemas';

interface ForgotPasswordFormProps {
  handleSubmitForm?: (phone: string) => void;
  handleTogglePopup?: (name: NamePopupTypes) => void
}

const ForgotPasswordFormTemplate:
  React.FC<ForgotPasswordFormProps> = ({ handleSubmitForm, handleTogglePopup }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [validCode, setValidCode] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const isSubmittingRef = useRef(false);

    const method = useForm<RegisterFormDataTypes>({
      resolver: yupResolver(registerSchema),
      mode: 'onChange',
      defaultValues: {
        code: '',
      },
    });

    const validateField = async (
      value: string,
    ) => {
      // setErrorMessage('');
      if (value) {
        const data: RegisterFormDataTypes = { code: value };
        try {
          const res = await getInfoMemberService(data.code);
          setPhone(res.phone);
          setName(res.name);
          setValidCode(true);
        } catch (error) {
          const errors = error as ErrorResponse[];

          if (Array.isArray(errors)) {
            console.log('errors: ', errors);

            errors.forEach((err) => {
              switch (err.code) {
                case 'E_1001':
                  method.setError('code', {
                    message: 'Mã cửa hàng đã được đăng ký',
                    type: 'validate'
                  });
                  // setErrorMessage('Mã cửa hàng đã được đăng ký');
                  break;
                case 404:
                  method.setError('code', {
                    message: 'Mã cửa hàng không tồn tại',
                    type: 'validate'
                  });
                  // setErrorMessage('Mã cửa hàng không tồn tại');
                  break;

                default:
                  method.setError('code', {
                    message: 'Mã cửa hàng không chính xác!',
                    type: 'validate'
                  });
                  // setErrorMessage('Mã cửa hàng không chính xác!');
                  break;
              }
            });
          } else {
            method.setError('code', {
              message: 'Mã cửa hàng không chính xác!',
              type: 'validate'
            });
            // setErrorMessage('Mã cửa hàng không chính xác!');
          }

          setPhone('');
          setName('');
          setValidCode(false);
        }
      }
    };

    const shopCodeDb = useDebounceInput(method.watch('code'), 500);

    useEffect(() => {
      validateField(shopCodeDb);
    }, [shopCodeDb]);

    const handleSubmit = async (data: RegisterFormDataTypes) => {
      if (!validCode) return;
      if (isSubmittingRef.current) return;
      setIsLoading(true);

      try {
        isSubmittingRef.current = true;
        if (phone) {
          await forgotPasswordService({
            shop_code: data.code,
            phone_number: phone
          });
          if (handleSubmitForm) {
            handleSubmitForm(phone);
          }
        }
      } catch (error) {
        isSubmittingRef.current = false;
        const errors = error as ErrorResponse[];
        console.log('errors: ', errors);

        if (Array.isArray(errors)) {
          errors.forEach((err) => {
            if (err.code === 'E_1001') {
              method.setError('code', {
                message: 'Mã cửa hàng đã được đăng ký',
                type: 'validate'
              });
            } else if (err.code === 'E_1002') {
              method.setError('code', {
                message: 'Gửi SMS không thành công',
                type: 'validate'
              });
              // setErrorMessage('Gửi SMS không thành công');
            } else if (err.code === 'memberNotFound') {
              method.setError('code', {
                message: 'Mã cửa hàng chưa được đăng ký',
                type: 'validate'
              });
            } else {
              method.setError('code', {
                message: 'Có lỗi xảy ra! Vui lòng thử lại!',
                type: 'validate'
              });
              // setErrorMessage('Có lỗi xảy ra! Vui lòng thử lại!');
            }
          });
        } else {
          method.setError('code', {
            message: 'Có lỗi xảy ra! Vui lòng thử lại!',
            type: 'validate'
          });
          // setErrorMessage('Có lỗi xảy ra! Vui lòng thử lại!');
        }
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <div className="t-forgotPasswordForm">
        <div className="t-forgotPasswordForm_noti">
          <Text modifiers={['center', '400', '28x40', 'yaleBlue']}>
            Quý khách hàng vui lòng điền mã cửa hàng
            được dán trên thiệp mời vào ô bên dưới
          </Text>
        </div>
        <div className="t-forgotPasswordForm_form">
          <Form method={method} submitForm={handleSubmit}>
            <div className="t-loginEvent_input">
              <Controller
                control={method.control}
                name="code"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    isRequired
                    modifiers={['border', 'h52']}
                    label="Nhập mã cửa hàng"
                    id="code"
                    value={value}
                    placeholder="Nhập mã cửa hàng"
                    error={error?.message}
                    handleChange={onChange}
                  />
                )}
              />
            </div>
            <div className="t-register_notiWrap">
              <div className="t-forgotPasswordForm_noti">
                <Text modifiers={['center', '400', '28x40', 'yaleBlue']}>
                  Vui lòng kiểm tra thông tin tên cửa hàng và số điện thoại
                </Text>
              </div>
            </div>
            <div className="t-register_formWrapper-field t-register_formWrapper-field-mb">
              <Input
                id="name"
                noCursor
                value={name}
                handleChange={(e) => setName(e.target.value)}
                label="Tên cửa hàng"
              />
            </div>
            <div className="t-register_formWrapper-field">
              <Input
                id="phone"
                noCursor
                value={phone}
                handleChange={(e) => setPhone(e.target.value)}
                label="Số điện thoại"
              />
            </div>
            <div className="t-forgotPasswordForm_button button-2025">
              <Button
                size="h44"
                loading={isLoading}
                type="submit"
                modifiers="primary-2025"
              >
                Gửi Lại Thông Tin
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  };
ForgotPasswordFormTemplate.defaultProps = {
  handleSubmitForm: undefined,
};

export default ForgotPasswordFormTemplate;
