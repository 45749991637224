/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import Heading from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import useWindowDimensions from 'hooks/useWindowDimension';
import mapModifiers from 'utils/functions';

export type TestimonialsDataType = {
  id?: number;
  img: string;
  name: string;
  role: string;
  position: string[];
};
interface TestimonialsProps extends MienTypes {
  dataTestimonials: TestimonialsDataType[];
  // dataTestimonials2: TestimonialsDataType[];
}

const TestimonialsItem: React.FC<TestimonialsDataType> = ({
  img,
  name,
  role,
  position,
}) => (
  <div className="t-testimonials_item">
    <div className="t-testimonials_item_inner">
      <div className="t-testimonials_item_wrapImg">
        <div className="t-testimonials_item_imgLayer" />
        <div className="t-testimonials_item_img">
          <Image src={img} alt={name} ratio="288x312" />
        </div>
      </div>
      <div className="mt-3">
        <div className="t-testimonials_item_role">
          <Text modifiers={['20x28', 'center', 'darkCerulean', '500']}>
            {role}
          </Text>
        </div>
        <div className="t-testimonials_item_name">
          <Text
            modifiers={['900', '32x44', 'center', 'darkCerulean']}
          >
            {name}
          </Text>
        </div>
        <div className="t-testimonials_item_position">
          {position.map((val, idx) => (
            <Text
              modifiers={['26x39', 'center', 'black', '400']}
              key={`item${idx.toString()}`}
            >
              {val}
            </Text>
          ))}
        </div>
      </div>
    </div>
  </div>
);
const TestimonialsTemplate: React.FC<TestimonialsProps> = ({
  dataTestimonials,
  // dataTestimonials2
  mien,
}) => {
  const { width } = useWindowDimensions();
  return (
    <div className="t-testimonials">
      <div className="t-testimonials_heading">
        <Heading
          type="h2"
          modifiers={['700', 'uppercase', 'center', '40x56', 'linearMain2025']}
        >
          {/* VỚI SỰ ĐỒNG HÀNH CỦA ĐẠI HỌC Y DƯỢC TP.HCM */}
          {mien === 'south'
            ? width && width < 580 ? (
              <>
                VỚI SỰ ĐỒNG HÀNH CỦA
                <br />
                ĐẠI HỌC Y DƯỢC TP. HỒ CHÍ MINH
              </>
            ) : 'VỚI SỰ ĐỒNG HÀNH CỦA ĐẠI HỌC Y DƯỢC TP. HỒ CHÍ MINH'
            : width && width < 580 ? (
              <>
                VỚI SỰ ĐỒNG HÀNH CỦA
                <br />
                BỆNH VIỆN ĐẠI HỌC Y HÀ NỘI
              </>
            ) : 'VỚI SỰ ĐỒNG HÀNH CỦA BỆNH VIỆN ĐẠI HỌC Y HÀ NỘI'}
          <br />
          {width && width < 580 ? (
            <>
              CÙNG CÁC CHUYÊN GIA
              <br />
              GIÀU KINH NGHIỆM
            </>
          ) : 'CÙNG CÁC CHUYÊN GIA GIÀU KINH NGHIỆM'}
        </Heading>
        <div className="decor-line" style={{ width: '50%' }} />
      </div>
      <div className="t-testimonials_containerFirst">
        <div
          className={mapModifiers(
            't-testimonials_wrapper',
            mien === 'north' ? 'two' : ''
          )}
        >
          {dataTestimonials.map((val, idx) => (
            <TestimonialsItem key={`item${idx.toString()}`} {...val} />
          ))}
        </div>
      </div>
      {/* <div className="t-testimonials_containerSecond">
      <div className="t-testimonials_title">
        <Text modifiers={['28x40', 'center', 'pattrickBlue', '700']}>
          BÁO CÁO VIÊN
        </Text>
      </div>
      <div className="t-testimonials_wrapper">
        {dataTestimonials2.map((val, idx) => (
          <TestimonialsItem
            key={`item${idx.toString()}`}
            {...val}
          />
        ))}
      </div>
    </div> */}
    </div>
  );
};

export default TestimonialsTemplate;
