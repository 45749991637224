/* eslint-disable jsx-a11y/media-has-caption */
import React, { forwardRef, useState } from 'react';

import Icon from 'components/atoms/Icon';
import useMutedAutoplay from 'hooks/useMutedAutoplay';
import useWindowSize from 'hooks/useWindowSize';

export interface PlayerProps {
  id?: string;
  src: string;
  isLoop?: boolean;
  isMuted?: boolean;
  autoplay?: boolean;
  isScreen?: boolean;
  useFullscreen?: boolean;
  onEnded?: () => void;
  onTimeUpdate?: (time: number) => void;
  onWaiting?: () => void;
  onPlaying?: () => void;
  onLoaded?: () => void;
}

const Player = forwardRef<HTMLVideoElement, PlayerProps>(({
  src, autoplay, id, isLoop, isMuted, isScreen, useFullscreen,
  onEnded, onTimeUpdate, onWaiting, onPlaying, onLoaded,
}, ref) => {
  const { width, height } = useWindowSize();
  // const [isFullscreen, setIsFullscreen] = useState(true);
  const [muted, setMuted] = useState(isMuted || false);
  const [fullscreen, setFullscreen] = useState(false);

  useMutedAutoplay(ref as any, setMuted);

  return (
    <div
      className="o-player"
      style={(isScreen || fullscreen) ? {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9999,
        width: `${width}px`,
        height: `${height}px`,
      } : {
        width: '100%',
        height: '100%',
      }}
    >
      <video
        style={{
          width: '100%',
          height: '100%',
        }}
        id={id}
        ref={ref}
        controls={false}
        playsInline
        loop={isLoop}
        muted={muted}
        onEnded={onEnded}
        autoPlay={autoplay}
        onTimeUpdate={(event) => onTimeUpdate && onTimeUpdate(event.currentTarget.currentTime)}
        onWaiting={onWaiting}
        onPlaying={onPlaying}
        onLoadedMetadata={onLoaded}
        data-wf-ignore="true"
        data-object-fit="cover"
        src={src}
        onContextMenu={(e) => { e.preventDefault(); }}
      />
      <div className="o-player_layer" />
      <div
        className="o-player_sound"
        onClick={() => setMuted(!muted)}
      >
        <Icon iconName={muted ? 'mute' : 'sound'} iconSize="24x24" />
      </div>
      {
        useFullscreen && (
          <div
            className="o-player_fullscreen"
            onClick={() => setFullscreen(!fullscreen)}
          >
            <Icon iconName={fullscreen ? 'minimize' : 'fullscreen'} iconSize="24x24" />
          </div>
        )
      }
    </div>
  );
});

export default Player;
