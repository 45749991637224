import React, { useRef } from 'react';

import doctorData, {
  agendaData, agendaData2, agendaDataMienBac, agendaDataMienBac2, doctorDataMienBac
} from 'assets/data';
import Heading from 'components/atoms/Heading';
import FloatButtonRegister from 'components/molecules/FloatButtonRegister';
import Container from 'components/organisms/Container';
import Footer from 'components/organisms/Footer';
import Player from 'components/organisms/Player';
import Agenda from 'components/templates/Agenda';
import Banner from 'components/templates/Banner';
import BannerHome from 'components/templates/Banner/component/home';
import Register from 'components/templates/Register';
import TestimonialsTemplate from 'components/templates/Testimonials';
import { scrollDownNextSection } from 'utils/functions';

const HomeContainer: React.FC<MienTypes> = ({ mien }) => {
  const registerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  return (
    <>
      {/* Banner  */}
      <Banner customContent isFullHeight>
        <BannerHome
          eventStart="2024-08-18T08:00:00+07:00"
          mien={mien}
          handleClick={() => scrollDownNextSection(registerRef)}
        />
      </Banner>
      <section className="p-home_teaser">
        <Container>
          <div className="p-home_teaser_wrapper">
            <div className="p-home_teaser_title">
              <Heading
                type="h2"
                modifiers={['700', 'uppercase', 'center', '40x56', 'linearMainText2025']}
              >
                THÔNG TIN CHƯƠNG TRÌNH
              </Heading>
            </div>
            <div className="p-home_teaser_video">
              <Player
                isMuted={false}
                autoplay
                isLoop
                ref={videoRef}
                useFullscreen
                src="https://cdn-mecury.3forcom.org/static/neat-event/clip/teaser.mp4"
              />
            </div>
          </div>
        </Container>
      </section>
      <section className="p-home_testimonials">
        <Container>
          <TestimonialsTemplate
            mien={mien}
            dataTestimonials={mien === 'south' ? doctorData : doctorDataMienBac}
          />
        </Container>
      </section>
      <section className="p-home_agenda">
        <Agenda
          agendaData={mien === 'south' ? agendaData : agendaDataMienBac}
          agendaData2={mien === 'south' ? agendaData2 : agendaDataMienBac2}
          mien={mien}
        />
      </section>
      <section className="p-home_register" ref={registerRef}>
        <Register mien={mien} />
      </section>
      <Footer />
      <FloatButtonRegister onClick={() => scrollDownNextSection(registerRef)} />
    </>
  );
};

export default HomeContainer;
