import React from 'react';

import icMobileDown from 'assets/icons/ic_mobile_down.svg';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import useWindowDimensions from 'hooks/useWindowDimension';

interface DeviceWrappedProps {
  children?: React.ReactNode;
  type?: boolean;
}

const DeviceWrapped = ({
  children, type = false,
}: DeviceWrappedProps) => {
  const { isMobile, isLandscape } = useWindowDimensions();

  return (
    <>
      {isMobile && !isLandscape && (
        <div className="t-deviceoriented">
          <div className="t-deviceoriented_wrapper">
            {!type && (
              <div className="t-deviceoriented_image">
                <Image src={icMobileDown} alt="mobile" ratio="1x1" />
              </div>
            )}
            <div className="t-deviceoriented_content">
              <Text modifiers={['18x28', 'pattrickBlue', 'center']}>
                Quý Khách vui lòng
                <Text modifiers={['700']}>
                  XOAY NGANG MÀN HÌNH
                </Text>
                Để tham gia chương trình
                <br />
                Chân thành cảm ơn
              </Text>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  );
};

DeviceWrapped.defaultProps = {
  children: <div />,
  type: false,
};

export default DeviceWrapped;
