/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import Popup from 'components/organisms/Popup';
import { maskPhoneNumber } from 'utils/functions';

interface SuccessForgotPasswordPopupProps {
  openPopup: boolean;
  phone: string;
  handleClose?: () => void;
}

const SuccessForgotPasswordPopup: React.FC<SuccessForgotPasswordPopupProps> = ({
  openPopup,
  phone,
  handleClose
}) => (
  <div className="t-successForgotPasswordPopup">
    <Popup
      modifiers="successForgotPasswordPopup"
      isOpenModal={openPopup}
      title="Gửi Lại Thông Tin Đăng Nhập Thành Công"
      handleClose={handleClose}
    >
      <div style={{ marginTop: '24px' }}>
        <Text modifiers={['400', 'center', '20x28', 'pattrickBlue']}>
          Thông tin đăng nhập của Quý khách hàng đã được gửi về
        </Text>
        <Text modifiers={['400', 'center', '20x28', 'pattrickBlue']}>
          số điện thoại
          {' '}
          {maskPhoneNumber(phone)}
          . Quý khách hàng vui lòng
        </Text>
        <Text modifiers={['400', 'center', '20x28', 'pattrickBlue']}>
          kiểm tra tin nhắn để đăng nhập vào chương trình
        </Text>
        <br />
        <div className="button-2025">
          <Button
            modifiers="primary-2025"
            onClick={handleClose}
          >
            Đăng Nhập
          </Button>
        </div>
      </div>
    </Popup>
  </div>
);

export default SuccessForgotPasswordPopup;
