import React, { useEffect } from 'react';

import HomeContainer from 'containers/home';
import { getTokenSession, setLocalStorageArea } from 'services/common/storage';

const Home: React.FC<MienTypes> = ({ mien }) => {
  useEffect(() => {
    const token = getTokenSession();
    if (!token) {
      console.log('REGISTER: ', mien);
      setLocalStorageArea(mien);
    }
  }, []);

  return (
    <div className="p-home">
      <HomeContainer mien={mien} />
    </div>
  );
};

export default Home;
