/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
// import bgYellow from 'assets/images/bg-finished.png';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import eventConfigs from '../../../configs';
import BackgroundPanel from '../BackgroundPanel';

import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import { Radio } from 'components/atoms/Radio';
import Text from 'components/atoms/Text';
import getCmeQuestionService, { postCmeExamService } from 'services/cme';
import { CmeAnswerParamsTypes, CmeQuestionDataTypes } from 'services/cme/type';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMemberProfileAction } from 'store/profile';

interface CmeSurveyProps {
  listQuestion: CmeQuestionDataTypes[]
}

const CmeSurvey: React.FC<CmeSurveyProps> = ({ listQuestion }) => {
  const [start, setStart] = useState(false);
  const { handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [currentSurvey, setCurrentSurvey] = useState(0);
  const [radioSelect, setRadioSelect] = useState(0);
  const [error, setError] = useState('');
  const [cmeQuestions, setCmeQuestion] = useState<CmeQuestionDataTypes[]>(listQuestion);
  const [numCorrect, setNumCorrect] = useState(0);
  const { data: memberInfo } = useAppSelector((state) => state.profile);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const checkPassed = useMemo(
    () => numCorrect >= cmeQuestions.length * 0.8,
    [numCorrect, cmeQuestions]
  );
  const checkDone = useMemo(
    () => currentSurvey !== (cmeQuestions.length),
    [currentSurvey, cmeQuestions]
  );

  useEffect(() => {
    if (!listQuestion.length) {
      (async () => {
        try {
          const res = await getCmeQuestionService();
          setCmeQuestion(res);
        } catch {
          // empty
        }
      })();
    }
  }, [listQuestion]);

  useEffect(() => {
    if (memberInfo && memberInfo?.allowCme === false) {
      setStart(true);
      setCurrentSurvey(cmeQuestions.length);
      setNumCorrect(memberInfo.certificateResult);
    }
  }, [memberInfo, cmeQuestions]);

  /* ------------Submit correct answer with each question------------ */
  const submitAnswerAction = async (data: CmeAnswerParamsTypes) => {
    setIsLoading(true);
    try {
      const res = await postCmeExamService(data);
      setRadioSelect(0);
      setCurrentSurvey(currentSurvey + 1);
      if (currentSurvey === cmeQuestions.length - 1) {
        dispatch(getMemberProfileAction());
      }
      // if (res.correct) {
      //   setNumCorrect(res.correct);
      // }
    } catch {
      toast.error('Gửi câu trả lời thất bại !!', {
        position: 'top-right',
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  /* ------------Submit answer with each question------------ */
  const handleSubmitForm = () => {
    const question = cmeQuestions[currentSurvey];
    if (radioSelect === 0) {
      setError('Vui lòng chọn câu trả lời');
    } else {
      const answer4: CmeAnswerParamsTypes = {
        exam_question_id: question.id,
        exam_answer_id: radioSelect,
      };
      submitAnswerAction(answer4);
    }
  };

  if (!start) {
    return (
      <BackgroundPanel
        title="KIỂM TRA KIẾN THỨC"
        modifier="firstCME"
      >
        <Text modifiers={['24x32', 'pattrickBlue', '500', 'center']}>
          Chứng chỉ Đào Tạo Y Khoa liên tục do Hội Y Học Thành Phố Hồ Chí Minh
          sẽ cấp cho các Chuyên viên y tế tham dự
          {' '}
          <b>
            ít nhất 80% thời lượng
          </b>
          {' '}
          chương trình và hoàn thành bài kiểm
          tra kiến thức với
          kết quả đạt
          {' '}
          <b>ít nhất 70% trở lên</b>
        </Text>
        <div className="t-cmesurvey_readyButton">
          <Button
            size="h44"
            onClick={() => setStart(true)}
          >
            THAM GIA KIỂM TRA
          </Button>
        </div>
      </BackgroundPanel>
    );
  }
  return (
    <>
      {
        currentSurvey < cmeQuestions.length ? (
          <div className="t-cmesurvey">
            <div className="t-cmesurvey_wrapper">
              <div className="t-cmesurvey_wrapTitle">
                <div className="t-cmesurvey_title">
                  <Heading
                    type="h2"
                    modifiers={[
                      '40x56',
                      'pattrickBlue',
                      'center',
                      '500',
                    ]}
                  >
                    <div dangerouslySetInnerHTML={{
                      __html:
                        cmeQuestions[currentSurvey]?.content,
                    }}
                    />
                  </Heading>
                </div>
              </div>
              <div className="t-cmesurvey_form">
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  <div className="t-cmesurvey_question center">
                    <ul className="answer">
                      {cmeQuestions[currentSurvey].answers.map((item, idx) => (
                        <li key={`${cmeQuestions[currentSurvey].id}-${item.id}${idx.toString()}`}>
                          <Radio
                            isSquare
                            onChange={() => {
                              setRadioSelect(item.id);
                              setError('');
                            }}
                            value={item.id}
                            checked={radioSelect === item.id}
                            name={`cme${item.id}`}
                          >
                            {item.content && item.content.toString()}
                          </Radio>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {error && <p className="t-survey_form_error">{error}</p>}
                  {
                    checkDone
                    && (
                      <div className="t-survey_button">
                        <Button
                          type="submit"
                          loading={isLoading}
                        >
                          {currentSurvey !== (cmeQuestions.length - 1) ? 'TIẾP THEO' : 'HOÀN THÀNH'}
                        </Button>
                      </div>
                    )
                  }
                </form>
              </div>
            </div>
          </div>
        ) : (
          <BackgroundPanel
            title="KẾT QUẢ KIỂM TRA KIẾN THỨC"
            modifier="resultCme"

          >
            <Text modifiers={['24x32', 'pattrickBlue', '500', 'center']}>
              Quý Chuyên viên Y Tế đã đạt được
              {' '}
              <Text type="span" modifiers={['24x32', checkPassed ? 'pattrickBlue' : 'red', 'center', '700']}>
                {(Number(numCorrect) / Number(cmeQuestions.length)) * 100}
                %
              </Text>
              {' '}
              bài kiểm tra kiến thức.
            </Text>
            {/* <Text modifiers={['24x32', 'pattrickBlue', '500', 'center']}>
              Quý vị hãy tiếp tục tham gia phần đóng góp ý kiến cho chương trình.
              <br />
              <br />
            </Text>
            <Text modifiers={['24x32', 'pattrickBlue', '500', 'center']}>
              Nhằm hỗ trợ Ban Tổ Chức không ngừng cải thiện chất
              lượng các buổi hội thảo trong tương lai,
              mời Quý vị vui lòng dành thời gian thực hiện các câu hỏi khảo sát đánh giá.
            </Text> */}
            <div className="t-cmesurvey_success_btn">
              <Button
                size="h44"
                onClick={() => {
                  navigate(eventConfigs.SLUG_PARAMS.THANKS);
                }}
              >
                HOÀN THÀNH
              </Button>
            </div>
          </BackgroundPanel>
        )
      }
      <ToastContainer />
    </>

  );
};

export default CmeSurvey;
