const SLUG_PARAMS = {
  HOME: '/',
  LIVESTREAM: '/livestream',
  THANKS: '/thanks',
  SURVEY: '/survey',
  FOYER: '/foyer',
  // LOGIN: '/login',
  LOGIN_MIENNAM: '/dang-nhap-miennam',
  LOGIN_MIENBACTRUNG: '/dang-nhap-mienbacmientrung',
  REGISTER: '/register',
  WAITING: '/waiting',
  EVENTWAITING: '/event-waiting',
  TVC: '/tvc',
  WAITINGLIVESTREAM: '/waiting-livestream',
  CME: '/cme',
  MN: '/miennam',
  MBMT: '/mienbacmientrung',
};

export const foyerVideo = 'https://cdn.3forcom.org/static/ensure/hanh_trinh_ensure.3k.mp4';

export default {
  SLUG_PARAMS,
};
