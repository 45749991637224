/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import Popup from 'components/organisms/Popup';

interface SuccessPopupProps extends MienTypes {
  // account?: {
  //   name: string;
  //   password: string;
  // };
  eventTime?: string;
  eventTime2?: string;
  openPopup: boolean;
  handleClose?: () => void;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({
  openPopup,
  // account,
  eventTime,
  eventTime2,
  handleClose,
  mien
}) => (
  <div className="t-policyPopup">
    <Popup
      modifiers="success"
      isOpenModal={openPopup}
      title="ĐĂNG KÝ THÀNH CÔNG"
      handleClose={handleClose}
    >
      <div style={{ marginTop: '24px' }}>
        <Text modifiers={['400', 'center', '20x28', 'pattrickBlue']}>
          Chúc mừng Quý khách hàng đã đăng ký tham dự thành công. Tên truy cập và
        </Text>
        <Text modifiers={['400', 'center', '20x28', 'pattrickBlue']}>
          Mật khẩu đã được gửi qua tin nhắn số điện thoại của Quý khách hàng.
        </Text>
        <Text modifiers={['400', 'center', '20x28', 'pattrickBlue']}>
          Chương trình đào tạo sẽ diễn ra vào lúc
        </Text>
        <Text modifiers={['700', 'center', '20x28', 'pattrickBlue']}>
          13 giờ 30 phút ngày 21/04/2025.
        </Text>
        <Text modifiers={['400', 'center', '20x28', 'pattrickBlue']}>
          Quý khách hàng vui lòng truy cập trang web
        </Text>
        <Text modifiers={['400', 'center', '20x28', 'pattrickBlue']}>
          {
            mien === 'south' ? (
              <a target="_self" href="https://hoinghikhachhangclp.com/miennam" style={{ color: '#263D76' }}>
                www.hoinghikhachhangclp.com/miennam
              </a>
            ) : (
              <a target="_self" href="https://hoinghikhachhangclp.com/mienbacmientrung" style={{ color: '#263D76' }}>
                www.hoinghikhachhangclp.com/mienbacmientrung
              </a>
            )
          }
        </Text>
        <Text modifiers={['400', 'center', '20x28', 'pattrickBlue']}>
          để tham dự chương trình.
        </Text>
        {/* <br />
      <Text modifiers={['royalAzure', '400', 'center', '14x22']}>
        vui lòng
        {' '}
        <strong>KHÔNG</strong>
        {' '}
        cung cấp thông tin đăng nhập bên dưới cho bất kỳ ai.
      </Text> */}
        <br />
        {/* <Text modifiers={['royalAzure', '400', 'center', '14x19']}>
        Tên tài khoản:
        {' '}
        <strong>
          {account?.name}
        </strong>
      </Text>
      <Text modifiers={['royalAzure', '400', 'center', '14x19']}>
        Mật mã:
        {' '}
        <strong>
          {account?.password}
        </strong>
      </Text> */}
        <br />
        <div className="t-otpPopup_button">
          <Button
            onClick={handleClose}
          >
            HOÀN THÀNH
          </Button>
        </div>
      </div>
    </Popup>
  </div>
);

SuccessPopup.defaultProps = {
  handleClose: undefined,
};

export default SuccessPopup;
