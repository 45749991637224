/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';

import BackgroundPanel from '../BackgroundPanel';

import bgCertificate from 'assets/images/bg-certificate.png';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import Datepicker from 'components/molecules/Datepicker';
import Pulldown, { OptionType } from 'components/molecules/Pulldown';
import Form from 'components/organisms/Form';
import Popup from 'components/organisms/Popup';
import useWindowDimensions from 'hooks/useWindowDimension';
import { updateCertificateCmeService } from 'services/cme';
import { UpdateCertificateCmeParams } from 'services/cme/type';
import { useAppDispatch } from 'store/hooks';
import { getMemberProfileAction } from 'store/profile';
import genderOptions from 'utils/constant';
import { formatDateDDMMYYYY, imageLoader } from 'utils/functions';
import { certificateSchema } from 'utils/schemas';

type CertificateFormTypes = {
  certificate_gender: OptionType | null;
  certificate_position: string;
} & UpdateCertificateCmeParams;

export type UserInfoCertification = {
  fullname: string;
  companyAddress: string;
  specialist: string;
};

interface CertificateProps {
  step?: number;
  userInfo?: UserInfoCertification;
  handleSubmit?: () => void;
}
const WIDTH = 2732;
const HEIGHT = 1944;
const TEXT_SIZE = 48;
const TEXT_COLOR = '#003974';

const Certificate: React.FC<CertificateProps> = ({ step, userInfo, handleSubmit }) => {
  const [background, setBackground] = useState<HTMLImageElement>();
  const [newImg, setNewImg] = useState('');
  const [isShowCertificate, setIsShowCertificate] = useState(false);
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [minHeight, setMinHeight] = useState(false);
  const dimension = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [certificateInfo, setCertificateInfo] = useState({
    certification_fullname: '',
    certification_birthday: '',
    certification_company_address: '',
    certification_gender: 0,
    certification_title: '',
    certification_birthplace: '',
  });
  const method = useForm<CertificateFormTypes>({
    resolver: yupResolver(certificateSchema),
    defaultValues: certificateInfo,
  });

  // SET CERTIFICATE WITH DEFAULT VALUE
  useEffect(() => {
    if (userInfo) {
      method.setValue('certification_fullname', userInfo.fullname || '');
      method.setValue('certification_company_address', userInfo.companyAddress || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  // Preview certification
  const handlePreviewCertificate = (data: CertificateFormTypes) => {
    setCertificateInfo({
      certification_fullname: data.certification_fullname,
      certification_company_address: data.certification_company_address,
      certification_birthday: data.certification_birthday,
      certification_gender: Number(data.certificate_gender?.value),
      certification_title: data.certificate_position,
      certification_birthplace: data.certification_birthplace
    });
    setIsShowCertificate(false);
    setIsShowPreview(true);
  };
  const canvas = useMemo(() => {
    const c = document.createElement('canvas');
    c.width = WIDTH;
    c.height = HEIGHT;

    return c;
  }, []);

  const ctx = useMemo(() => canvas.getContext('2d')!, [canvas]);

  // Download certification
  const handleDownload = async () => {
    try {
      setLoading(true);
      dispatch(getMemberProfileAction());
      const {
        certification_fullname, certification_birthday,
        certification_company_address,
        certification_gender, certification_title, certification_birthplace
      } = certificateInfo;

      await updateCertificateCmeService({
        certification_fullname,
        certification_company_address,
        certification_birthday: formatDateDDMMYYYY(certification_birthday, '/'),
        certification_gender,
        certification_title,
        certification_birthplace,
      });
      // const a = document.createElement('a');
      // a.download = 'certificate.png';
      // a.href = newImg;
      // a.click();

      // a.remove();
      if (handleSubmit) {
        handleSubmit();
      }
    } catch {
      toast.error('Lỗi hệ thống, vui lòng thử lại !!', {
        position: 'top-right',
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (dimension && dimension.height && dimension.height < 786) {
      setMinHeight(true);
    } else {
      setMinHeight(false);
    }
  }, [dimension]);

  useEffect(() => {
    let mounted = true;

    (async () => {
      const bg = await imageLoader(bgCertificate);

      if (mounted) setBackground(bg);
    })();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (step === 0) {
      setIsShowCertificate(true);
    }
  }, [step]);

  useEffect(() => {
    // setImmediate(() => {
    if (!background) {
      return;
    }
    const {
      certification_birthday,
      certification_company_address,
      certification_fullname,
    } = certificateInfo;

    ctx.drawImage(background, 0, 0, WIDTH, HEIGHT);

    ctx.font = `bold ${TEXT_SIZE}px Myriad Pro`;

    ctx.fillStyle = TEXT_COLOR;
    ctx.fillText(`${certificateInfo?.certification_title} ${certificateInfo?.certification_fullname}`, 940, 670);
    ctx.fillText(`${formatDateDDMMYYYY(certificateInfo?.certification_birthday)}`, 940, 755);
    ctx.fillText(`${certificateInfo.certification_birthplace}`, 940, 840);
    ctx.fillText(certification_company_address.toUpperCase(), 940, 925);

    setNewImg(canvas.toDataURL());
    // });
  }, [background, ctx, canvas, certificateInfo]);

  return (
    <>
      <div className="t-certificate" style={{ backgroundImage: `url(${bgCertificate})` }}>
        {/* CERTIFICATION POPUP  */}
        {isShowCertificate && (
          <BackgroundPanel
            title="THÔNG TIN CÁ NHÂN"
            modifier="formCertificate"
          >
            <div className="t-certificate_form">
              <Form method={method} submitForm={handlePreviewCertificate}>
                {/* Fullname  */}
                <div className="t-certificate_form_input">
                  <Controller
                    control={method.control}
                    name="certification_fullname"
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        isRequired
                        label="Họ tên đầy đủ (In hoa có dấu)"
                        id="certification_fullname"
                        value={value}
                        placeholder="Ví dụ: NGUYỄN VĂN A"
                        type="text"
                        error={error?.message}
                        modifiers={['border', 'h44']}
                        handleChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="t-certificate_form_input">
                  <Controller
                    name="certification_birthday"
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error, isDirty, invalid },
                    }) => (
                      <div className="t-cvEduInfoModal-field">
                        <Datepicker
                          required
                          id=""
                          startDate={value}
                          handleChangeDate={onChange}
                          placeholder="Ví dụ: 15/02/1993"
                          label="NGÀY THÁNG NĂM SINH"
                          error={error?.message}
                          // isValidate={isDirty || invalid}
                          innerRef={ref}
                          modifiers="infoModal"
                        />
                      </div>
                    )}
                  />
                </div>

                {/* Gender  */}
                <div className="t-certificate_form_input">
                  <Controller
                    name="certificate_gender"
                    control={method.control}
                    render={({ field, fieldState }) => (
                      <Pulldown
                        {...field}
                        isRequired
                        isError={!!fieldState.error}
                        placeholder="Ví dụ: NAM"
                        options={genderOptions}
                        name="certificate_gender"
                        label="Giới tính"
                        modifier="secondary"
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                </div>
                {/* Birth Place  */}
                <div className="t-certificate_form_input">
                  <Controller
                    control={method.control}
                    name="certification_birthplace"
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        isRequired
                        label="NƠI SINH"
                        id="certification_birthplace"
                        value={value}
                        placeholder="Ví dụ: Hà Nội"
                        type="text"
                        error={error?.message}
                        handleChange={onChange}
                        modifiers={['border', 'h44']}
                      />
                    )}
                  />
                </div>
                {/* Position  */}
                <div className="t-certificate_form_input">
                  <Controller
                    control={method.control}
                    name="certificate_position"
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        isRequired
                        label="HỌC HÀM/ HỌC VỊ"
                        id="certificate_position"
                        value={value}
                        placeholder="Ví dụ: ĐIỀU DƯỠNG"
                        type="text"
                        error={error?.message}
                        handleChange={onChange}
                        modifiers={['border', 'h44']}
                      />
                    )}
                  />
                </div>
                {/* Company Address  */}
                <div className="t-certificate_form_input">
                  <Controller
                    control={method.control}
                    name="certification_company_address"
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        isRequired
                        label="Nơi công tác (In hoa có dấu, không viết tắt)"
                        id="certification_company_address"
                        value={value}
                        placeholder="NƠI CÔNG TÁC: Bệnh viện Đa Khoa Nông Nghiệp"
                        type="text"
                        error={error?.message}
                        handleChange={onChange}
                        modifiers={['border', 'h44']}
                      />
                    )}
                  />
                </div>
                <div className="t-certificate_form_submit">
                  <Button type="submit" size="h44">
                    <div className="d-flex align-items-center">
                      <div className="m-2">
                        <Icon iconName="eyeBlue" iconSize="16" />
                      </div>
                      Xem trước chứng chỉ
                    </div>
                  </Button>
                </div>
              </Form>
            </div>
          </BackgroundPanel>
        )}

        {/* PREVIEW CERTIFICATION POPUP  */}
        <Popup
          modifiers="previewCertificate"
          isOpenModal={isShowPreview}
        >
          <div className="t-certificate_award">
            <img src={bgCertificate} alt="show" />
            <div className="t-certificate_field t-certificate_field-name">
              {`${certificateInfo?.certification_title} ${certificateInfo?.certification_fullname}`}
            </div>
            <div className="t-certificate_field t-certificate_field-birthday">
              {`${formatDateDDMMYYYY(certificateInfo?.certification_birthday)}`}
            </div>
            <div className="t-certificate_field t-certificate_field-birthplace">
              {certificateInfo.certification_birthplace}
            </div>
            <div className="t-certificate_field t-certificate_field-address">
              {certificateInfo?.certification_company_address}
            </div>
          </div>
          <div className="t-certificate_field_button">
            <div className="t-certificate_field-back">
              <Button
                size="h44"
                onClick={() => {
                  setIsShowCertificate(true);
                  setIsShowPreview(false);
                }}
              >
                QUAY LẠI
              </Button>
            </div>
            <div className="t-certificate_field-confirm">
              <Button
                size="h44"
                loading={loading}
                onClick={handleDownload}
              >
                XÁC NHẬN THÔNG TIN
              </Button>
            </div>
          </div>
        </Popup>
      </div>
      <ToastContainer />
    </>
  );
};

Certificate.defaultProps = {
  step: undefined,
  handleSubmit: undefined,
  userInfo: undefined,
};

export default Certificate;
