/* eslint-disable */
import React, { useMemo } from 'react';

import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import Container from 'components/organisms/Container';
import useWindowDimensions from 'hooks/useWindowDimension';

export type AgendaTypes = {
  time: string;
  subTitle?: string[];
  subTitleMb?: string[];
  title: string;
  speaker?: string[];
  classSubTitle?: string;
};
interface AgendaProps {
  agendaData: AgendaTypes[];
  agendaData2: AgendaTypes[];
  mien?: string;
}

const AgendaItem: React.FC<AgendaTypes> = ({
  time, title, subTitle, subTitleMb, speaker, classSubTitle
}) => {
  const { width } = useWindowDimensions();
  const subData = useMemo(() => {
    if (!width || (width && width > 580)) {
      return subTitle;
    }
    return subTitleMb;
  }, [width])
  return (
    <div className="t-agenda_item">
      {/* <div className="t-agenda_item_datetime">
      <Text modifiers={['darkCerulean', '700', '40x48']}>
        {time}
      </Text>
    </div> */}
      <div className="t-agenda_item_content">
        <div className="t-agenda_item_title">
          <Text modifiers={['darkCerulean', '700', '40x48']} className="t-agenda_item_title-title">
            {title}
          </Text>
          {subData && subData.length > 0 && subData.map((item, index) => (
            <div key={`t-agenda_item_subTitle_${index.toString()}`} className={'t-agenda_item_subTitle' + `${classSubTitle ? ` ${classSubTitle}` : ''}`}>
              <Text modifiers={['700', '40x48', 'uppercase']}>
                {item}
              </Text>
            </div>
          ))}
        </div>
        {
          speaker && speaker.length
          && (
            <div className="t-agenda_item_speaker">
              {speaker.map((item, idx) => (
                <Text key={`speaker-${idx.toString()}`} modifiers={['500', '40x48', 'italic', 'gray']} content={item} />
              ))}
            </div>
          )
        }
      </div>
    </div>
  )
};

const Agenda: React.FC<AgendaProps> = ({
  agendaData, mien,
  agendaData2,
}) => (
  <div className="t-agenda">
    <Container>
      <div className="t-agenda_list">
        <div className="t-agenda_heading">
          <Heading
            type="h2"
            modifiers={['700', 'uppercase', 'center', '40x56', 'linearMainText2025']}
          >
            NỘI DUNG CHƯƠNG TRÌNH
          </Heading>
        </div>
        <div className="t-agenda_list_wrap">
          <div className="t-agenda_listItem t-agenda_left">
            {
              agendaData.map((val, idx) => (
                <AgendaItem {...val} key={`item-${idx.toString()}`} />
              ))
            }
          </div>
          <div className="t-agenda_listItem t-agenda_right">
            {
              agendaData2.map((val, idx) => (
                <AgendaItem {...val} key={`item-${idx.toString()}`} />
              ))
            }
          </div>
        </div>
        <div className="t-agenda_note">
          <span className="t-agenda_note-title">Lưu ý:{' '}</span>
          <span className="t-agenda_note-description">
            Chương trình có cấp Chứng nhận hoàn tất tham dự đào tạo từ
            {mien === 'nam' ? ' Đại học Y Dược TP. Hồ Chí Minh' : ' Bệnh viện Đại học Y Hà Nội'} dành cho Quý khách hàng
            tham dự ít nhất 80% thời lượng nội dung giảng dạy và hoàn thành
            đạt ít nhất 60% bài kiểm tra cuối chương trình.
          </span>
        </div>
      </div>
    </Container>
  </div>
);

Agenda.defaultProps = {
};

export default Agenda;
