/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import abbottLogo from 'assets/images/new2025/abbott-logo-black.png';
import logoBenhVienDaiHocYHN from 'assets/images/new2025/logoBenhVienDaiHocYHN.png';
import yduoc from 'assets/images/new2025/yduoc.png';
import Text from 'components/atoms/Text';

interface BannerLoginProps extends MienTypes {
  children?: React.ReactNode;
}

const BannerLogin: React.FC<BannerLoginProps> = ({ children, mien }) => (
  <div className="t-bannerLogin">
    <div className="t-bannerLogin_content">
      <div className="t-bannerLogin_logo">
        <div className="t-bannerLogin_logo_item">
          <img
            src={mien === 'south' ? yduoc : logoBenhVienDaiHocYHN}
            alt="logo"
          />
        </div>
        <div className="t-bannerLogin_logo_item t-bannerLogin_logo_item-abbott">
          <img src={abbottLogo} alt="logo" />
        </div>
        {/* <div className="t-bannerLogin_logo_item">
            <img src={logo3a} alt="logo" />
          </div> */}
      </div>
      <div className="t-bannerLogin_title2">
        <Text type="span" modifiers={['linearGold2025', '40x48', '700', 'center']}>Chương Trình Hội Thảo Khoa Học</Text>
        <div className="decor-line" />
      </div>
      <div className="t-bannerLogin_content2">
        <Text modifiers={['linearMain2025', 'brandonGrotesque', '52x70', 'center', '900']}>
          ĐÀO TẠO TƯ VẤN
        </Text>
        <Text modifiers={['linearMain2025', 'brandonGrotesque', '52x70', 'center', '900']}>
          DINH DƯỠNG CHO NGƯỜI LỚN TUỔI
        </Text>
        <Text modifiers={['linearMain2025', 'brandonGrotesque', '52x70', 'center', '900']}>
          VÀ NGƯỜI MẮC ĐÁI THÁO ĐƯỜNG
        </Text>
      </div>
      <div className="t-bannerLogin_datetime">
        <Text modifiers={['stPatricksBlue', 'center', '28x36', '600', 'brandon']} className="t-bannerLogin_datetime-time">
          13:00 - 15:30
        </Text>
        <div className="t-bannerLogin_datetime-divider-vertical" />
        <Text modifiers={['stPatricksBlue', 'center', '28x36', '600', 'brandon']}>
          Ngày 17.04.2025
        </Text>
        {/* <div className="t-bannerLogin_datetime-divider-vertical" />
          <div className="t-bannerLogin_datetime_right">
            <Text modifiers={['stPatricksBlue', 'left', '28x36', '600', 'brandon']}>
              Xem trực tiếp tại:
              <br />
              www.hoinghikhachhangclp.com
            </Text>
          </div> */}
      </div>
    </div>
  </div>
);

export default BannerLogin;
