/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Loading from 'components/atoms/Loading';
import RedirectToPage from 'helpers/redirectToPage';
import useInAppDetect from 'hooks/useInAppDetect';
import { getTokenSession, clearLocalStorage, setLocalStorageArea } from 'services/common/storage';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMemberProfileAction } from 'store/profile';
import { getSystemAction } from 'store/system';
import SocketPush from 'utils/SocketPush';

const MainLayoutContainer: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const status = useAppSelector((state) => state.system.socketStatus);
  const redirectToPage = useAppSelector((state) => state.system.data?.redirectToPage);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = getTokenSession();
    dispatch(getSystemAction());
    if (token) {
      dispatch(getMemberProfileAction())
        .unwrap()
        .then((res) => {
          console.log('RES: ', res?.area);

          setLocalStorageArea(res?.area);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }

    if (!token) {
      clearLocalStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inApp = useInAppDetect();

  if (!redirectToPage) return null;

  if (inApp) {
    return inApp === 'ios' ? (
      <span>
        Vui lòng nhấn và giữ
        {' '}
        <a href={window.location.href}>liên kết này</a>
        {' '}
        trong 02 giây để chuyển đổi trình duyệt phù hợp và trải nghiệm
      </span>
    ) : (
      <span>
        Vui lòng nhấn vào
        {' '}
        <a href={`intent://${window.location.host}${window.location.pathname}${window.location.search}#Intent;scheme=${window.location.protocol.replace(/\W/g, '')};action=android.intent.action.VIEW;end;`}>liên kết này</a>
        {' '}
        để chuyển đổi trình duyệt phù hợp và trải nghiệm.
      </span>
    );
  }

  if (loading) return <Loading variant="fullscreen" />;

  return (
    <main className="wrapper">
      <RedirectToPage />
      <Outlet />
      <SocketPush />
      {/* <div style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        color: 'green',
        fontWeight: 'bold',
        zIndex: 10
      }}
      >
        {status === 1 ? '' : status === 2 ? '' : ''}
      </div> */}

    </main>
  );
};

export default MainLayoutContainer;
