/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/require-default-props */
import DOMPurify from 'dompurify';
import React from 'react';

import mapModifiers from 'utils/functions';

export type TextStyle = (GeneralTextStyle)[];

interface TextProps {
  modifiers?: TextStyle;
  type?: 'p' | 'span' | 'div';
  content?: string;
  children?: React.ReactNode;
  className?: string;
}

const Text: React.FC<TextProps> = ({
  modifiers,
  type = 'p',
  children,
  content,
  className,
}) => {
  const Element = type;
  return (
    <>
      {content ? (
        <Element
          className={`${mapModifiers('a-text', modifiers)} ${className}`}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
        />
      ) : (
        <Element className={`${mapModifiers('a-text', modifiers)} ${className}`}>
          {children}
        </Element>
      )}
    </>
  );
};

Text.defaultProps = {
};

export default Text;
