/* eslint-disable */
import React, { useMemo } from "react";

// import bannerDecorMb from 'assets/images/new/home/banner-decorator-mobile.png';
import abbottLogo from "assets/images/new2025/abbott-logo-black.png";
import step1 from "assets/images/new/home/step1.png";
import step2 from "assets/images/new/home/step2.png";
import step3 from "assets/images/new/home/step3.png";
// import logo3a from 'assets/images/new2025/3a.png';
import bannerRegisterMobile from "assets/images/new2025/banner-register-mobile.png";
import bannerDecor from "assets/images/new2025/kv-image.png";
import logoBenhVienDaiHocYHN from "assets/images/new2025/logoBenhVienDaiHocYHN.png";
import yduoc from "assets/images/new2025/yduoc.png";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";
import Link from "components/atoms/Link";
import Text from "components/atoms/Text";
import useWindowDimensions from "hooks/useWindowDimension";
import mapModifiers from "utils/functions";

interface BannerHomeProps extends MienTypes {
  eventStart: string;
  handleClick?: () => void;
}

export const BannerContent: React.FC<{ notFit?: boolean }> = ({ notFit }) => (
  <>
    <div className="t-bannerHome_title">
      <Text modifiers={["white", "center", "26x39", "700", "brandon"]}>
        HỘI THẢO KHOA HỌC
      </Text>
    </div>
    <div className="t-bannerHome_sologan">
      <Text modifiers={["linearGoldNew", "center", "30x48", "700"]}>
        NÂNG CAO CHĂM SÓC DINH DƯỠNG CHO BỆNH NHÂN NGOẠI KHOA
        <br />
        NHU CẦU THIẾT THỰC GIÚP HỒI PHỤC SỚM SAU PHẪU THUẬT VÀ SAU XUẤT VIỆN
      </Text>
    </div>
    <div className={mapModifiers("t-bannerHome_steps", notFit && "notFit")}>
      <div className="t-bannerHome_step">
        <Image src={step1} alt="Trước phẩu thuật" ratio="46x37" />
      </div>
      <div className="t-bannerHome_step">
        <Image src={step2} alt="Sau phẩu thuật" ratio="46x37" />
      </div>
      <div className="t-bannerHome_step">
        <Image src={step3} alt="Sau xuất viện" ratio="46x37" />
      </div>
    </div>
  </>
);

const BannerHome: React.FC<BannerHomeProps> = ({
  eventStart,
  handleClick,
  mien,
}) => {
  const { width, height } = useWindowDimensions();

  const isMobile = useMemo(() => {
    if (width && height) {
      if (width && width <= 991 && width < height) {
        return true;
      }
    }
    return false;
  }, [width, height]);

  return (
    <div className="t-bannerHome">
      <div className="t-bannerHome_content">
        <div className="t-bannerHome_info">
          <div className="t-bannerHome_logo">
            <div className="t-bannerHome_logo_item">
              <img
                src={mien === "south" ? yduoc : logoBenhVienDaiHocYHN}
                alt="logo"
              />
              {/* <Image src={mien === 'nam' ? yduoc : logoBenhVienDaiHocYHN} ratio="1x1" /> */}
            </div>
            <div className="t-bannerHome_logo_item t-bannerHome_logo_item-abbott">
              <img src={abbottLogo} alt="logo" />
              {/* <Image src={abbottLogo} ratio="153x174" /> */}
            </div>
            {/* <div className="t-bannerHome_logo_item">
            <img src={logo3a} alt="logo" />
          </div> */}
          </div>
          <div className="t-bannerHome_title2">
            <Text
              type="span"
              modifiers={["linearGold2025", "40x48", "700", "center"]}
            >
              Chương Trình Hội Thảo Khoa Học
            </Text>
            <div className="decor-line" />
          </div>
          <div className="t-bannerHome_content2">
            <Text
              modifiers={[
                "linearMain2025",
                "brandonGrotesque",
                "52x70",
                "center",
                "900",
              ]}
            >
              ĐÀO TẠO TƯ VẤN
            </Text>
            <Text
              modifiers={[
                "linearMain2025",
                "brandonGrotesque",
                "52x70",
                "center",
                "900",
              ]}
            >
              DINH DƯỠNG CHO NGƯỜI LỚN TUỔI
            </Text>
            <Text
              modifiers={[
                "linearMain2025",
                "brandonGrotesque",
                "52x70",
                "center",
                "900",
              ]}
            >
              VÀ NGƯỜI MẮC ĐÁI THÁO ĐƯỜNG
            </Text>
          </div>
          <div className="t-bannerHome_datetime">
            <div className="t-bannerHome_datetime_main">
              <Text
                modifiers={[
                  "stPatricksBlue",
                  "center",
                  "28x36",
                  "600",
                  "brandon",
                ]}
              >
                13:30 - 16:00 | Ngày 21.04.2025
              </Text>
            </div>
            <Text
              modifiers={["stPatricksBlue", "center", "28x36", "600", "brandon"]}
            >
              Xem trực tiếp tại:
              <br />
              <Link useExternal href="https://www.hoinghikhachhangclp.com">
                <Text
                  modifiers={[
                    "stPatricksBlue",
                    "center",
                    "28x36",
                    "600",
                    "brandon",
                  ]}
                >{`hoinghikhachhangclp.com/${mien === "south" ? "miennam" : "mienbacmientrung"
                  }`}</Text>
              </Link>
            </Text>
            <div className="t-bannerHome_datetime_replaytime">
              <Text modifiers={["stPatricksBlue", "center", "20x24", "brandon"]}>
                Xem phát lại:{" "}
                <Text type="span" modifiers={["600"]}>
                  Vào lúc 8:30 - 11:00 Ngày 22.04.2025
                </Text>
              </Text>
            </div>
            <div className="t-bannerHome_datetime-button button-2025">
              <Button type="button" onClick={handleClick} modifiers="primary-2025">Đăng ký</Button>
            </div>
          </div>
        </div>
        <div className="t-bannerHome_note">
          {mien === "south" ? (
            <Text modifiers={["dimGray", "center", "18x24", "400", "brandon"]}>
              Chương trình phối hợp tổ chức bởi Trung Tâm Đào Tạo Nhân Lực Y Tế
              Theo Nhu Cầu Xã Hội,
              <br />
              Đại Học Y Dược Thành Phố Hồ Chí Minh, Abbott và Công ty TNHH Dinh
              dưỡng 3A (Việt Nam)
            </Text>
          ) : (
            <Text modifiers={["dimGray", "right", "18x24", "400", "brandon"]}>
              Chương trình phối hợp tổ chức Bệnh viện Đại Học Y Hà Nội,
              <br />
              Trường Đại Học Y Hà Nội, Abbott và Công ty TNHH Dinh dưỡng 3A
              (Việt Nam)
            </Text>
          )}
        </div>
        {/* <div className="t-bannerHome_countdown">
          <div className="t-bannerHome_countdown_title">
            <Text modifiers={['white', 'center', '24x36', '400']}>
              Sự kiện diễn ra sau
            </Text>
          </div>
          <Countdown endTime={eventStart} />
        </div> */}
        {/* <div className="t-bannerHome_button">
          <Button size="h56" onClick={handleClick}>
            ĐĂNG KÝ NGAY
          </Button>
        </div> */}
      </div>
      <div className="t-bannerHome_image">
        <img src={isMobile ? bannerRegisterMobile : bannerDecor} alt="banner" />
        <div className="t-bannerHome_noteLeft">
          <Text modifiers={["black", "center", "18x24", "400", "brandon"]}>
            Chuyên gia Abbott
          </Text>
        </div>
      </div>
    </div>
  );
};

export default BannerHome;
