import React, { useMemo, useRef } from 'react';

import Loading from 'components/atoms/Loading';
import Player from 'components/organisms/Player';
import useWindowDimensions from 'hooks/useWindowDimension';
import { useAppSelector } from 'store/hooks';

const Tvc: React.FC = () => {
  const dataConfig = useAppSelector((state) => state.system.data);
  const area = useAppSelector((state) => state.profile.data?.area);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { height } = useWindowDimensions();

  const linkArea = useMemo(() => {
    if (dataConfig && area) {
      if (area === 'south') {
        return dataConfig.linkVideoSouth || '';
      }
      return dataConfig.linkVideoNorth || '';
    }
    return '';
  }, [dataConfig, area]);

  if (!dataConfig) { return <Loading variant="fullscreen" />; }

  return (
    <div className="p-tvc" style={{ height: `${height}px` }}>
      <div className="t-foyer_concept_video">
        <Player
          isMuted={false}
          autoplay
          isLoop
          ref={videoRef}
          src={linkArea}
        />
      </div>
    </div>
  );
};

export default Tvc;
